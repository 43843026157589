import React, { Component } from 'react'
import { Form, Icon, InputOnChangeData, Label, Ref } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import { OribiApp } from '../../types'

interface Props {
  list: 'userwords' | 'nowarns'
  words: string[]
  app: OribiApp
  i18n: MessageGetter
  handleChange: (words: string[]) => void
}
interface State {
  currentWord: string
}

export default class WordList extends Component<Props, State> {
  inputRef: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)

    this.state = {
      currentWord: ''
    }

    this.inputRef = React.createRef()
  }

  componentDidMount = () => {
    // Component mounts when settings panel mounts and when list is updated
    this.focusInput()
  }

  addWord = (word = this.state.currentWord) => {
    if ( !word ) return;

    // Remove whitespace and duplicates
    const uniqueWords = this.props.words
      .concat(word.split(','))
      .reduce((words: string[], current: string) => {
        const word = current.trim()
        if ( !word || words.includes(word) ) return words

        return words.concat(word)
      }, [])

    this.props.handleChange(uniqueWords)
    this.setState({ currentWord: '' })
    // Will trigger componentdidmount
  }

  removeWord = (word: string) => {
    const { words } = this.props
    const index = words.indexOf(word)

    words.splice(index, 1)
    this.props.handleChange(words)
  }

  editWord = (word: string) => {
    this.removeWord(word)
    this.setState({ currentWord: word })
    this.focusInput()
  }

  focusInput = (delay = 0) => {
    const { current } = this.inputRef
    if ( current !== null ) {
      const input = current.querySelector('input')
      if ( input !== null ) {
        setTimeout(() => {
          input.focus()
        }, delay)
      }
    }
  }

  handleInputChange = (_event: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    this.setState({ currentWord: value })
  }

  render = () => {
    const { words, list, app, i18n } = this.props
    const { currentWord } = this.state
    
    // Sort words alphabetically
    const sortedWords = words.sort((a, b) => {
      const x = a.toLowerCase()
      const y = b.toLowerCase()
      if ( x < y ) return -1
      if ( x > y ) return 1
      return 0
    })

    return <>
      <p>{replacePlusWithIcon(
        i18n(`settings_wordlist_${list}_description`, [app])
      )}</p>
      <p><strong>{ i18n(`settings_wordlist_${list}_header`, [app]) }</strong></p>
      <div className='wordlist'>
        <Label.Group>
        { sortedWords.map((word, i) => 
          <UserWord word={ word } key={ i } remove={ this.removeWord } edit={ this.editWord } />
        )}
        </Label.Group>
      </div>
      <Form onSubmit={() => {
        this.addWord()
      }}>
        <Ref innerRef={this.inputRef}>
          <Form.Input
            placeholder={ i18n('settings_wordlist_placeholder') }
            value={ currentWord }
            onChange={ this.handleInputChange }
            action={{
              primary: true,
              disabled: !currentWord.length,
              icon: 'plus'
            }}
          />
        </Ref>
      </Form>
    </>
  }
}

interface UserWordProps {
  word: string
  remove: (word: string) => void
  edit: (word: string) => void
}

const UserWord = ({ word, remove, edit }: UserWordProps) => {
  const handleLabelClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ( event.currentTarget.nodeName === 'I' ) {
      remove(word)
    } else {
      edit(word)
    }
  }

  return (
    <Label
      as='a'
      onClick={ handleLabelClick }
      style={{ margin: '0 5px 5px 0', fontWeight: 'normal' }}
    >
      { word }
      <Icon name='delete' />
    </Label>
  )
}

const replacePlusWithIcon = (string: string): JSX.Element[] =>
  string.split('+').map((part, i, { length }) => {
    const isLastPart = (i === length - 1)
    return <React.Fragment key={i}>
      { part }{ !isLastPart && <Icon name='plus' /> }
    </React.Fragment>
  })