import React from 'react'
import { Button } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import PropertiesService from '../../api/storage'
import { User } from '../../types'

interface LogOutBtnProps {
  i18n: MessageGetter
  onError?: (message: string) => void
  inverted?: boolean
  as?: 'a'
  size?: 'tiny' // "big" | "small" | "mini" | "tiny" | "medium" | "large" | "huge" | "massive"
  fluid?: boolean
  label?: 'top' | 'bottom'
  user?: User
  storage: PropertiesService
}

const LogOutBtn = (props: LogOutBtnProps) => {
  const onSuccess = () => {
    props.storage.deleteLocal('msalAccessToken')
    window.location.reload()
  }

  const { user, label, i18n } = props

  const Label = ({ user }: { user: User }) => (
    <p className='logout-btn-label'>{i18n('signed_in_as_x', [user.email])}</p>
  )
  return (
    <>
      {label === 'top' && !!user && <Label user={user} />}
      <Button
        content={props.i18n('sign_out')}
        as={props.as}
        size={props.size}
        fluid={props.fluid}
        icon='sign out'
        basic
        inverted={props.inverted}
        onClick={() => {
          const simulatedUser = props.storage.getLocal('simulatedUser')
          if (simulatedUser !== null) {
            props.storage.deleteLocal('simulatedUser')
            window.location.reload()
            return
          }

          const dialogUrl = `${window.location.origin}/dialog/logout`
          Office.context.ui.displayDialogAsync(
            dialogUrl,
            { height: 50, width: 30 },
            (asyncResult) => {
              // Handle dialog creation issues
              if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                if (typeof props.onError === 'function') {
                  props.onError(asyncResult.error.message)
                }
                return
              }

              const dialog = asyncResult.value

              // Handle message from dialog
              dialog.addEventHandler(
                Office.EventType.DialogMessageReceived,
                (_data: any) => {
                  dialog.close()
                  onSuccess()
                }
              )

              // Handle dialog events
              dialog.addEventHandler(
                Office.EventType.DialogEventReceived,
                (_data: any) => {
                  dialog.close()
                  onSuccess()
                }
              )
            }
          )
          //   Office.context.ui.displayDialogAsync(dialogLogoutUrl,
          //     {height: 40, width: 30},
          //     (result) => {
          //         if (result.status === Office.AsyncResultStatus.Failed) {
          //             displayError(`${result.error.code} ${result.error.message}`);
          //         }
          //         else {
          //             logoutDialog = result.value;
          //             logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage);
          //             logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent);
          //         }
          //     }
          // );
        }}
      />
      {label === 'bottom' && !!user && <Label user={user} />}
    </>
  )
}

export default LogOutBtn
