import React, { createContext, FC, useState } from 'react'

type SelectedWordContext = {
  word: string
  setWord?: React.Dispatch<React.SetStateAction<string>>
}

const defaultContext: SelectedWordContext = {
  word: ''
}

export const SelectedWordContext = createContext(defaultContext)

const Provider: FC = ({ children }) => {
  const [word, setWord] = useState('')

  return (
    <SelectedWordContext.Provider
      value={{
        word,
        setWord
      }}
    >
      {children}
    </SelectedWordContext.Provider>
  )
}

export default Provider
