import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import {
  SpellcheckStatus,
  OrilangoResult,
  OrilangoResultType,
  SpellcheckHistory
} from '.'
import { MessageGetter } from '../../api/i18n'

interface Props {
  status: SpellcheckStatus
  result: OrilangoResult
  visibleSuggestions: number
  selectedWord?: string
  history: SpellcheckHistory
  userChangedSelection: boolean
  i18n: MessageGetter
  handleClickChange: () => void
  handleClickMore: () => void
  handleClickNext: () => void
  handleClickUndo: () => void
  handleClickAdd: () => void
}

interface State {
  popupActive: boolean
}

interface CommandButtonProps {
  content?: string
  header: string
  action?: () => void
  icon: string
  textPosition?: 'bottom left' | 'bottom right'
  disabled?: boolean
  popupDisabled?: boolean
  color?:
    | 'red'
    | 'orange'
    | 'yellow'
    | 'olive'
    | 'green'
    | 'teal'
    | 'blue'
    | 'violet'
    | 'purple'
    | 'pink'
    | 'brown'
    | 'grey'
    | 'black' // |'facebook'|'google plus'|'vk'|'twitter'|'linkedin'|'instagram'|'youtube'
}
const CommandButton = (props: CommandButtonProps) => {
  const {
    content,
    icon,
    textPosition,
    disabled,
    popupDisabled,
    action,
    header,
    color
  } = props

  return (
    <Popup
      inverted
      disabled={!!popupDisabled}
      content={content}
      position={textPosition || 'bottom center'}
      openOnTriggerClick={false}
      closeOnTriggerClick
      header={header}
      closeOnTriggerMouseLeave
      trigger={
        <Button
          size='large'
          icon={icon}
          style={{ borderRadius: 0 }}
          disabled={disabled}
          onClick={action}
          color={color}
        />
      }
    />
  )
}

export default class CommandBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      popupActive: true
    }
  }

  get isInactive(): boolean {
    const { status } = this.props
    return (
      status === SpellcheckStatus.IDLE || status === SpellcheckStatus.LOADING
    )
  }

  get shouldDisableChangeBtn(): boolean {
    if (this.isInactive) return true
    const { selectedWord } = this.props
    return !selectedWord
  }
  get shouldDisableMoreBtn(): boolean {
    if (this.isInactive) return true
    const {
      result: { suggestions },
      visibleSuggestions
    } = this.props

    return visibleSuggestions >= suggestions.length
  }
  get shouldDisableNextBtn(): boolean {
    const { status } = this.props
    return status === SpellcheckStatus.LOADING
  }
  get shouldDisableUndoBtn(): boolean {
    if (this.props.status === SpellcheckStatus.LOADING) return true
    return this.props.history.length < 2
  }
  get shouldDisableAddBtn(): boolean {
    if (this.isInactive) return true

    const disabledTypes = [
      OrilangoResultType.CHEK_PUNCTUATION,
      OrilangoResultType.DONE,
      OrilangoResultType.GRAMMAR,
      OrilangoResultType.SEPARATION
    ]
    return disabledTypes.includes(this.props.result.type)
  }

  render() {
    const { result, status, selectedWord, i18n } = this.props
    const { original, type } = result

    const isDone =
      status === SpellcheckStatus.STOPPED && type === OrilangoResultType.DONE
    const userChangedSelection =
      // status === SpellcheckStatus.STOPPED &&
      // type !== OrilangoResultType.DONE &&
      this.props.userChangedSelection

    const lastPart = this.props.history[this.props.history.length - 2]
    let undoBtnTitle
    if (!!lastPart && !!lastPart.usedSuggestion) {
      undoBtnTitle = `'${lastPart.usedSuggestion.word}' → '${lastPart.result.original}'`
    } else if (!!lastPart) {
      undoBtnTitle = `'${lastPart.result.original}' ←`
    }

    // Disable hover popups on touch devices
    const popupDisabled = Office.context.touchEnabled

    return (
      <Button.Group widths='5'>
        <CommandButton
          header={i18n(
            original === selectedWord
              ? 'command_dont_change'
              : 'command_do_change'
          )}
          content={
            original === selectedWord
              ? i18n('command_keep', [selectedWord])
              : `'${original}' → '${selectedWord}'`
          }
          icon='check'
          textPosition='bottom left'
          disabled={this.shouldDisableChangeBtn}
          action={() => this.props.handleClickChange()}
          popupDisabled={popupDisabled}
        />
        <CommandButton
          header={i18n('command_show_more')}
          icon='sort amount down'
          disabled={this.shouldDisableMoreBtn}
          action={() => this.props.handleClickMore()}
          popupDisabled={popupDisabled}
        />
        <CommandButton
          header={
            isDone || userChangedSelection
              ? i18n('command_start_over')
              : i18n('command_next')
          }
          icon={'arrow right'}
          disabled={this.shouldDisableNextBtn}
          action={() => this.props.handleClickNext()}
          content={
            userChangedSelection
              ? i18n('command_start_over_onenote_hint')
              : undefined
          }
          popupDisabled={popupDisabled}
        />
        <CommandButton
          header={i18n('command_undo')}
          content={undoBtnTitle}
          icon='undo'
          disabled={this.shouldDisableUndoBtn}
          action={() => this.props.handleClickUndo()}
          popupDisabled={popupDisabled}
        />
        <CommandButton
          header={
            type === OrilangoResultType.CONFUSABLE_WORD
              ? i18n('command_ignore')
              : i18n('command_learn')
          }
          content={
            type === OrilangoResultType.CONFUSABLE_WORD
              ? i18n('command_ignore_hint', [original])
              : i18n('command_learn_hint', [original])
          }
          icon='add'
          textPosition='bottom right'
          disabled={this.shouldDisableAddBtn}
          action={() => this.props.handleClickAdd()}
          popupDisabled={popupDisabled}
        />
      </Button.Group>
    )
  }
}
