import './style.scss'
import React from 'react'
import Translator, { MessageGetter, UILanguage } from '../../api/i18n'
import { OribiApp, OfficeInfo, HostType } from '../../types'
import {
  getDocumentBody,
  DocumentBody,
  setSelection,
  replaceSelection,
  selectBeginning,
  getDocumentSelection,
  OneNoteOutLine
} from './api'
import CommandBar from './CommandBar'
import StatusBar from './StatusBar'
import PropertiesService, { FirstLang } from '../../api/storage'
import { version } from '../../../package.json'
import Suggestions from './Suggestions'
import ExcerptBar from './ExcerptBar'
import { oribiSpeak, speak, TTSProvider } from '../TTS/api'
import OnboardingModal from './Onboarding'
import { Message, Transition } from 'semantic-ui-react'
// import { SkrivaTextUpgradeMessage } from './SkrivaTextUpgradeMessage'

export enum SpellcheckStatus {
  IDLE,
  LOADING,
  STOPPED
}

interface OrilangoRequest {
  text: string
  type: 'checknext'
  waschanged: 0 | 1
  selectionStart: number
  selectionLength: number
  listsize: 1 | 2 | 3
  homophones: 0 | 1
  grammar: 0 | 1
  spaces: 0 | 1
  american: 0 | 1
  firstlang: 0 | 1 | 2 | 3 | 4 | 5 // 0:none, 1:Swedish, 2:Danish, 3:Norwegian, 4:Finnish, 5:German
  userwords: string[]
  nowarns: string[]
  email: string
  source: string // "gdocs_" + app.slug + "_ + version
}

export enum OrilangoResultType {
  DONE = 0,
  NOT_FOUND,
  CONFUSABLE_WORD,
  CHEK_PUNCTUATION,
  GRAMMAR,
  AMERICAN,
  BRITISH,
  SEPARATION
}

interface OrilangoResultSuggestion {
  word: string
  sentence: string
  translation: string
}

// The language the user writes in
type SpellcheckLanguge = 'en' | 'sv' | 'da' | 'de'

export interface SpellcheckSuggestion extends OrilangoResultSuggestion {
  index: number
  firstlang: FirstLang
  lang: SpellcheckLanguge
  words: string[]
}

export interface OrilangoResult {
  type: OrilangoResultType // ResultType
  original: string // the current stopped-at word
  selectionStart: number
  selectionLength: number
  message: string
  ncandidates: number
  capitalize: number
  suggestions: OrilangoResultSuggestion[]
}

interface PartOfHistory {
  result: OrilangoResult
  request: OrilangoRequest
  usedSuggestion?: SpellcheckSuggestion
}
export type SpellcheckHistory = PartOfHistory[]

interface Props {
  i18n: Translator
  propertiesService: PropertiesService
  app: OribiApp
  officeInfo: OfficeInfo
  oribiSpeakExtensionId: string
  speechSynthesisVoice?: SpeechSynthesisVoice
  setSelectedWord: (word: string) => void
}

interface State {
  status: SpellcheckStatus
  result: OrilangoResult
  documentBody: DocumentBody
  suggestions: SpellcheckSuggestion[]
  visibleSuggestions: number
  activeSuggestion?: SpellcheckSuggestion
  history: SpellcheckHistory
  lastError?: Error
  userChangedSelection?: boolean
  speakingSuggestion?: SpellcheckSuggestion
  displayOnboarding?: boolean
  activeOutline?: OneNoteOutLine
  selectedWord?: string
}

const emptyResult: OrilangoResult = {
  original: '',
  type: OrilangoResultType.DONE,
  selectionStart: 0,
  selectionLength: 0,
  message: '',
  ncandidates: 0,
  capitalize: 0,
  suggestions: []
}

// When spellchecker sets the document selection, store information here for
// matching with user made selection later on
interface TriggeredSelection {
  startOffset: number
  length: number
  timestamp: number
}

export default class SpellChecker extends React.Component<Props, State> {
  checkFrom: number
  mountTimeout?: NodeJS.Timeout
  checkLang: SpellcheckLanguge
  lastSelection: TriggeredSelection
  i18n: MessageGetter
  lang: UILanguage

  get isLoading(): boolean {
    return this.state.status === SpellcheckStatus.LOADING
  }

  get isDone() {
    const { status, result } = this.state
    return (
      status === SpellcheckStatus.STOPPED &&
      result.type === OrilangoResultType.DONE
    )
  }

  get isIdle() {
    return this.state.status === SpellcheckStatus.IDLE
  }

  get ttsProvider(): TTSProvider | null {
    const { oribiSpeakExtensionId, speechSynthesisVoice } = this.props
    if (!!oribiSpeakExtensionId) return TTSProvider.ORIBI_SPEAK
    if (!!speechSynthesisVoice) return TTSProvider.LOCAL
    return null
  }

  constructor(props: Props) {
    super(props)

    this.lang = props.i18n.lang

    const onboarded = props.propertiesService.getLocal('onboarded')
    let displayOnboarding = true

    if (onboarded !== null) {
      for (const [lang, host] of onboarded as [UILanguage, HostType][]) {
        if (lang === this.lang && host === this.props.officeInfo.host) {
          displayOnboarding = false
          break
        }
      }
    }

    this.state = {
      status: displayOnboarding
        ? SpellcheckStatus.IDLE
        : SpellcheckStatus.LOADING,
      documentBody: {
        text: ''
      },
      result: emptyResult,
      visibleSuggestions: 0,
      suggestions: [],
      history: [],
      displayOnboarding
    }

    this.checkFrom = 0
    this.lastSelection = {
      startOffset: 0,
      length: 0,
      timestamp: 0
    }

    switch (this.props.app) {
      case OribiApp.STAVA_REX:
        this.checkLang = 'sv'
        break
      case OribiApp.STAVLET:
        this.checkLang = 'da'
        break
      case OribiApp.SCHREIBREX:
        this.checkLang = 'de'
        break
      default:
        this.checkLang = 'en'
        break
    }

    this.i18n = props.i18n.getMessage
  }

  componentDidMount = async () => {
    // The spellchecker mounts and unmounts a few times on load, so let's wait
    // until things have settled down before checking
    this.mountTimeout = setTimeout(() => {
      if (this.state.status === SpellcheckStatus.LOADING) this.checkNext()

      const { host } = this.props.officeInfo
      if (host === HostType.OneNote) {
        getDocumentBody(host).then(({ outlines = [] }) => {
          this.setState({ activeOutline: outlines[0] })
        })
      }
    }, 200)

    // Listen for selection changes
    this.toggleSelectionChangeHandler(true)
    this.toggleKeyboardEventHandler(true)
  }

  componentWillUnmount = () => {
    if (this.mountTimeout) clearTimeout(this.mountTimeout)

    this.toggleSelectionChangeHandler(false)
    this.toggleKeyboardEventHandler(false)
  }

  componentDidCatch = (error: Error) => {
    this.handleError(error)
  }

  componentDidUpdate = () => {
    if (!!this.selectedWord) {
      this.props.setSelectedWord(this.selectedWord)
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { code, altKey } = event
    if (!altKey) return

    const isDigit = code.match(/^Digit(\d)$/)
    if (!isDigit) return

    const digit = Number(isDigit[1])
    if (digit > this.state.visibleSuggestions) return

    const suggestionIndex = Number(digit) - 1

    const { suggestions, activeSuggestion } = this.state
    const newActiveSuggestion = suggestions[suggestionIndex]

    if (activeSuggestion !== newActiveSuggestion) {
      this.setState({
        activeSuggestion: newActiveSuggestion
      })
    }
  }

  handleKeyUp = (event: KeyboardEvent) => {
    const { code, altKey } = event
    const { activeSuggestion, suggestions } = this.state

    if (code === 'ArrowUp' || code === 'ArrowDown') {
      const index = !!activeSuggestion ? activeSuggestion.index : -1
      const newActiveIndex = code === 'ArrowDown' ? index + 1 : index - 1
      const newActiveSuggestion = suggestions[newActiveIndex]
      if (!!newActiveSuggestion) {
        this.setState({ activeSuggestion: newActiveSuggestion })

        let { visibleSuggestions } = this.state
        if (newActiveIndex + 1 > visibleSuggestions) {
          visibleSuggestions++
          this.setState({ visibleSuggestions }, () => {
            // const activeNode = document.querySelector('.list .active.item') as HTMLElement
            // if ( !!activeNode ) activeNode.focus()
          })
        }
      }
    }

    if (!altKey) return

    const isDigit = code.match(/^Digit(\d)$/)
    if (!isDigit) return

    const digit = Number(isDigit[1])
    if (digit > this.state.visibleSuggestions) return

    const suggestionIndex = Number(digit) - 1

    const newActiveSuggestion = suggestions[suggestionIndex]
    if (activeSuggestion === newActiveSuggestion) {
      this.useSuggestion()
    } else {
      this.setState(
        {
          activeSuggestion: newActiveSuggestion
        },
        () => setTimeout(this.useSuggestion, 400)
      )
    }
  }

  toggleKeyboardEventHandler = (toggleOn: boolean) => {
    let action: Function
    if (toggleOn === true) {
      action = window.addEventListener
    } else {
      action = window.removeEventListener
    }

    action('keydown', this.handleKeyDown)
    action('keyup', this.handleKeyUp)
  }

  toggleSelectionChangeHandler = (toggleOn: boolean) => {
    // Only handle user selection events in OneNote
    // if ( this.props.officeInfo.host !== HostType.OneNote ) return;

    const doc = Office.context.document
    let action: Function

    if (toggleOn === true) {
      action = doc.addHandlerAsync
    } else {
      action = doc.removeHandlerAsync
    }

    action(
      Office.EventType.DocumentSelectionChanged,
      this.handleSelectionChange
    )
  }

  handleSelectionChange = (data: Office.DocumentSelectionChangedEventArgs) => {
    // Don't handle selection if loading or done
    if (this.props.officeInfo.host === HostType.Word) {
      if (this.isLoading || (this.isDone && !this.isIdle)) return
    } else {
      if (this.isLoading) return
    }

    // Sometimes this handler is triggered even when we aren't listening for
    // selection change events. It seems to be called with an OSF.DDA.AsyncResult
    // object instead of OSF.DDA.DocumentSelectionChangedEventArgs, which contains
    // a `value` prop that we can use to distinguish the two from each other.
    if ('value' in data || !this.lastSelection.timestamp) return

    // If the spellchecker tried to set selection less than a second and a half ago,
    // assume that this event was triggered by ourselves
    if (Date.now() - this.lastSelection.timestamp < 1500) return

    const { host } = this.props.officeInfo

    // Reset history if document text changed
    getDocumentBody(host).then(documentBody => {
      if (documentBody.text !== this.state.documentBody.text) {
        this.setState({ history: [] })
      }
    })

    // Only reset checkFrom in OneNote, since OneNote doesn't provide an API
    // to get selectionData...
    if (host === HostType.OneNote) {
      OneNote.run(async context => {
        const outline = context.application.getActiveOutlineOrNull()
        if (outline === null) {
          this.checkFrom = 0
          this.setState({
            userChangedSelection: true,
            activeOutline: undefined
          })

          return context.sync()
        }

        outline.load('id')
        await context.sync()

        await new Promise(async resolve => {
          const documentBody = await getDocumentBody(host)
          if (documentBody.text !== this.state.documentBody.text) {
            this.setState({ documentBody }, () => resolve(null))
          } else {
            resolve(null)
          }
        })

        const { id } = outline
        const { outlines } = this.state.documentBody

        if (outlines) {
          const activeOutline = outlines.find(outline => outline.id === id)

          if (activeOutline) {
            this.checkFrom = activeOutline.startOffset
            this.setState({ activeOutline })
          } else {
            // TODO update document body
            this.checkFrom = 0
            this.setState({ activeOutline: outlines[0] })
          }
        }

        return context.sync()
      })
    }
  }

  useSuggestion = async (suggestion?: SpellcheckSuggestion) => {
    suggestion = suggestion || this.state.activeSuggestion
    if (suggestion === undefined) return

    // Don't bother running replacement operations if word is the same as original
    const { original } = this.state.result
    try {
      if (suggestion.word !== original) {
        this.toggleSelectionChangeHandler(false)

        const { host } = this.props.officeInfo

        // Re-select original
        // (user might have moved cursor)
        const { selectionStart, original } = this.state.result
        await setSelection(host, original, selectionStart)

        // TODO: Move logic to replaceSelection?
        let selectedText = ''
        if (host === HostType.Word) {
          // Make sure that selected word is still original
          // (user might have changed document contents)
          selectedText = await new Promise(resolve => {
            Office.context.document.getSelectedDataAsync(
              Office.CoercionType.Text,
              result => {
                const value = String(result.value || '')
                resolve(value)
              }
            )
          })
        } else {
          selectedText = original
        }

        // Only replace if original word matches current selection. If not, go
        // straight to checkNext.
        if (selectedText === original) {
          await replaceSelection(host, suggestion.word)
          const { history } = this.state
          const currentHistoryPos = history[history.length - 1]
          if (!!currentHistoryPos) {
            currentHistoryPos.usedSuggestion = suggestion
          }
        } else {
          throw new Error()
        }

        this.toggleSelectionChangeHandler(true)
      }

      return this.checkNext()
    } catch (error) {
      const message =
        (error as Error)?.message || this.i18n('fatal_error_header')
      this.handleError(new Error(message))
    }
  }

  addToHistory = (part: PartOfHistory): Promise<SpellcheckHistory> => {
    return new Promise(resolve => {
      this.setState(
        prevState => {
          let { history } = prevState

          // Prevent multiple "Finished" from being added to history
          const doneIndex = history.findIndex(
            ({ result }) => result.type === OrilangoResultType.DONE
          )
          if (doneIndex !== -1) history.splice(doneIndex, 1)

          history.push(part)
          return {
            ...prevState,
            history: history.slice(-10)
          }
        },
        () => resolve(this.state.history)
      )
    })
  }

  checkNext = async () => {
    this.setState({
      status: SpellcheckStatus.LOADING,
      result: emptyResult,
      activeSuggestion: undefined,
      suggestions: [],
      visibleSuggestions: 0,
      documentBody: {
        text: ''
      },
      lastError: undefined
    })

    const {
      propertiesService,
      officeInfo: { host, platform },
      app
    } = this.props

    const documentBody = await getDocumentBody(host)
    this.setState({ documentBody })

    const { text } = documentBody

    const documentSelection = await getDocumentSelection(host)
    let isEndOfTextSelected = false

    // Always check from beginning of document on first run
    if (documentSelection !== undefined) {
      const { selectionStart, selectionLength } = documentSelection
      const selectionEnd = selectionStart + selectionLength
      const selectedText = text.substring(selectionStart, selectionEnd)
      const selectionEndHasWhitespace = /\s$/.test(selectedText)

      // Don't restart check if last word is selected
      isEndOfTextSelected = selectionEnd === text.length

      // If the document has a selection, check from selectionEnd if checkFrom
      // (last stop selEnd) matches selectionEnd, otherwise check from selectionStart
      if (selectionLength > 0) {
        // If all document text is selected, selLength will be 1 longer than text
        if (selectionStart === 0 && selectionLength === text.length + 1) {
          this.checkFrom = selectionEnd
        } else if (selectionEnd === this.checkFrom) {
          this.checkFrom = selectionEnd
        } else {
          // If paragraph consists of a single selected word, it ends with a
          // whitespace and we should'nt check from beginning of selection
          if (!selectionEndHasWhitespace) this.checkFrom = selectionStart
        }
      } else {
        this.checkFrom = selectionStart
      }
    }

    // If the caret is at end of document but without selection, check from beginning
    if (this.checkFrom === text.length && !isEndOfTextSelected) {
      this.checkFrom = 0
    }

    // Build request data
    const storage = propertiesService.get(
      'userwords',
      'homophones',
      'listsize',
      'grammar',
      'english_type',
      'firstlang',
      'nowarns',
      'sentences',
      'spaces'
    )

    // Convert stored values to request data
    const requestData: OrilangoRequest = {
      text: text,
      type: 'checknext',
      selectionStart: this.checkFrom,
      selectionLength: 0,
      waschanged: 0,

      get listsize() {
        switch (storage.listsize) {
          case '1':
            return 1
          case '3':
            return 3
          default:
            return 2
        }
      },
      get firstlang() {
        switch (storage.firstlang) {
          case FirstLang.SV:
            return 1
          case FirstLang.DA:
            return 2
          case FirstLang.NO:
            return 3
          case FirstLang.FI:
            return 4
          case FirstLang.DE:
            return 5
          default:
            return 0
        }
      },
      american: storage.english_type === 'american' ? 1 : 0,
      homophones: storage.homophones ? 1 : 0,
      grammar: storage.grammar ? 1 : 0,
      spaces: storage.spaces ? 1 : 0,
      userwords: storage.userwords || [],
      nowarns: storage.nowarns || [],
      email: 'string',
      source: `${encodeURIComponent(app)}_${version}_${host}_${platform}`
    }

    type OrilangEngine = 'sv' | 'da' | 'de' | 'en'
    let engine: OrilangEngine
    switch (app) {
      case OribiApp.STAVA_REX:
        engine = 'sv'
        break
      case OribiApp.STAVLET:
        engine = 'da'
        break
      case OribiApp.SCHREIBREX:
        engine = 'de'
        break
      default:
        engine = 'en'
        break
    }

    const url = encodeURI(`https://ols.oribi.se/v1/${engine}/spellcheck`)
    const options = {
      method: 'POST',
      body: JSON.stringify(requestData)
    }

    const result: OrilangoResult = await fetch(url, options).then(response =>
      response.json()
    )
    this.handleOrilangoResult(result, requestData)
  }

  handleOrilangoResult = (result: OrilangoResult, request: OrilangoRequest) => {
    this.addToHistory({ result, request })
    // Word puts a newline at the beginning of the document, which makes Orilango
    // confused if first word is lowercase but should be capitalized
    if (!!result.original) {
      // TODO: No original in result if done
      const { text } = this.state.documentBody
      const textBeforeOriginal = text.substring(0, result.selectionStart)
      const isFirstWordInDoc = textBeforeOriginal.trim().length === 0
      const firstLetter = result.original[0]
      const isFirstLetterLowerCase = firstLetter === firstLetter.toLowerCase()

      if (isFirstWordInDoc && isFirstLetterLowerCase) {
        result.capitalize = 1
      }
    }

    const { selectionLength, selectionStart, type } = result
    this.checkFrom =
      type === OrilangoResultType.DONE ? 0 : selectionStart + selectionLength

    // Make sure each suggestion has an index :)
    const suggestions: SpellcheckSuggestion[] = []
    for (let index = 0; index < result.suggestions.length; index++) {
      const suggestion = result.suggestions[index]
      const { sentence, translation } = suggestion
      const firstlang = request.firstlang
      const lang = this.checkLang
      let { word } = suggestion
      if (result.capitalize) {
        word = word[0].toUpperCase() + word.substring(1)
      }

      let words: string[]
      if (!!sentence) {
        const regExp = /<([^<>]+)>/g
        const matches = sentence.match(regExp)
        if (matches !== null) {
          words = matches.map(word => word.replace(regExp, '$1'))
        } else {
          words = [word]
        }
      } else {
        words = [word]
      }

      suggestions.push({
        word,
        sentence,
        translation,
        index,
        firstlang,
        lang,
        words
      })
    }

    const visibleSuggestions = result.ncandidates
    const activeSuggestion =
      visibleSuggestions === 1 ? suggestions[0] : undefined

    const selectionEnd = selectionStart + selectionLength
    let activeOutline
    const { outlines } = this.state.documentBody
    if (outlines) {
      const outlineIndex = outlines.findIndex(({ startOffset, endOffset }) => {
        return startOffset <= selectionStart && endOffset >= selectionEnd
      })
      activeOutline = outlines[outlineIndex]
    }

    this.setState({
      status: SpellcheckStatus.STOPPED,
      result,
      activeSuggestion,
      visibleSuggestions,
      suggestions,
      activeOutline
    })

    const { officeInfo } = this.props

    if (result.type === OrilangoResultType.DONE) {
      selectBeginning(officeInfo.host)
    } else {
      this.setState({ userChangedSelection: false })
      this.lastSelection = {
        startOffset: selectionStart,
        length: selectionLength,
        timestamp: Date.now()
      }

      setSelection(officeInfo.host, result.original, selectionStart).catch(
        this.handleError
      )
    }
  }

  showMore = () => {
    this.setState(prevState => ({
      visibleSuggestions: prevState.visibleSuggestions + 1
    }))
  }

  undo = async () => {
    const { history } = this.state
    // Remove current from history
    // This changes state but doesn't trigger state update
    // Don't proceed if the history is empty (which it shouldn't be able to be)
    if (!history.pop()) return

    const lastPart = history.pop()
    if (!lastPart) return

    const { result, request, usedSuggestion } = lastPart

    // Just step back and display past result if nothing was changed
    if (!usedSuggestion) {
      return this.handleOrilangoResult(result, request)
    }

    const { selectionStart, original } = result
    const selectionLength = usedSuggestion.word.length
    const {
      officeInfo: { host }
    } = this.props

    this.setState({ userChangedSelection: false })
    this.lastSelection = {
      startOffset: selectionStart,
      length: selectionLength,
      timestamp: Date.now()
    }

    await setSelection(host, usedSuggestion.word, selectionStart)
    await replaceSelection(host, original, 'start')

    this.checkFrom = selectionStart
    this.setState({ history }, this.checkNext)
  }

  addToWordlist = async () => {
    const {
      result: { type, original }
    } = this.state
    const wordlist =
      type === OrilangoResultType.CONFUSABLE_WORD ? 'nowarns' : 'userwords'

    const stored = this.props.propertiesService.get(wordlist)
    const words = stored[wordlist] || []
    words.push(original)

    this.props.propertiesService.set({
      [wordlist]: words
    })

    this.checkNext()
  }

  handleError = (error: Error) => {
    this.setState({ lastError: error })
  }

  speak = (suggestion: SpellcheckSuggestion) => {
    const text = suggestion.sentence.replace(/[<>]/g, '') || suggestion.word
    const lang = this.checkLang
    const { oribiSpeakExtensionId, speechSynthesisVoice } = this.props

    this.setState({
      speakingSuggestion: suggestion
    })

    const handleError = () => {
      const errorMessage = this.i18n('error_tts_utterance')
      this.handleError(new Error(errorMessage))
    }

    const handleSuccess = () => {
      this.setState({ speakingSuggestion: undefined })
    }

    if (!!oribiSpeakExtensionId) {
      let estimatedPlaybackTime = 1000 + text.length * 100
      setTimeout(handleSuccess, estimatedPlaybackTime)
      oribiSpeak({
        extensionId: oribiSpeakExtensionId,
        text,
        lang,
        onError: handleError
      })
    } else if (!!speechSynthesisVoice) {
      speak(text, speechSynthesisVoice).then(success => {
        handleSuccess()
        if (!success) handleError()
      })
    }
  }

  get selectedWord() {
    let { selectedWord, activeSuggestion } = this.state
    if (!activeSuggestion) {
      selectedWord = undefined
    } else if (!selectedWord) {
      selectedWord = activeSuggestion.word
    } else if (!activeSuggestion.words.includes(selectedWord)) {
      selectedWord = activeSuggestion.word
    }

    return selectedWord
  }

  render() {
    const {
      result,
      status,
      documentBody,
      lastError,
      activeOutline
    } = this.state
    const loading = status === SpellcheckStatus.LOADING
    const displayEmptyDocMessage = this.isDone && !documentBody.text.trim()

    const { selectedWord } = this

    return (
      <div id='spellchecker'>
        <div className='command-bar'>
          <CommandBar
            status={status}
            result={result}
            visibleSuggestions={this.state.visibleSuggestions}
            selectedWord={selectedWord}
            // activeSuggestion={ this.state.activeSuggestion }
            i18n={this.i18n}
            history={this.state.history}
            userChangedSelection={!!this.state.userChangedSelection}
            handleClickNext={this.checkNext}
            handleClickChange={this.useSuggestion}
            handleClickMore={this.showMore}
            handleClickUndo={this.undo}
            handleClickAdd={this.addToWordlist}
          />
        </div>
        {/* end .command-bar */}

        {
          this.props.officeInfo.host === HostType.OneNote && (
            <div className='excerpt-bar'>
              <ExcerptBar
                documentText={documentBody.text}
                selectionLength={result.selectionLength}
                selectionStart={result.selectionStart}
                status={status}
                original={result.original}
                onError={console.warn}
                outlines={documentBody.outlines || []}
                activeOutline={
                  displayEmptyDocMessage ? undefined : activeOutline
                }
                setCheckFrom={newValue => {
                  this.checkFrom = newValue
                }}
              />
            </div>
          ) /* end .excerpt-bar */
        }
        {/* TEMP: Hide message until customers are more open to the transition
        <SkrivaTextUpgradeMessage
          app={this.props.app}
          i18n={this.i18n}
          host={this.props.officeInfo.host}
        /> */}

        <div className='status-bar'>
          <StatusBar
            status={status}
            i18n={this.i18n}
            result={this.state.result}
          />
        </div>
        {/* end .status-bar */}

        {!!lastError && (
          <Transition transitionOnMount animation='fade down'>
            <Message
              error
              content={lastError.message}
              onDismiss={() => this.setState({ lastError: undefined })}
            />
          </Transition>
        )}

        <div className='suggestions-wrapper'>
          {status !== SpellcheckStatus.IDLE && (
            <Suggestions
              app={this.props.app}
              i18n={this.i18n}
              original={this.state.result.original}
              suggestions={this.state.suggestions}
              activeSuggestion={this.state.activeSuggestion}
              visibleSuggestions={this.state.visibleSuggestions}
              loading={loading}
              ttsProvider={this.ttsProvider}
              handleClick={suggestion => {
                this.setState(prevState => {
                  const { activeSuggestion } = prevState
                  return activeSuggestion === suggestion
                    ? prevState
                    : {
                        ...prevState,
                        activeSuggestion: suggestion
                      }
                })
              }}
              handleDblClick={suggestion => {
                this.useSuggestion(suggestion)
              }}
              handleContextMenuChange={suggestion => {
                this.useSuggestion(suggestion)
              }}
              handleContextMenuSpeak={this.speak}
              speakingSuggestion={this.state.speakingSuggestion}
              displayEmptyDocMessage={displayEmptyDocMessage}
              handleWordClick={word => {
                if (word.text === selectedWord) return
                this.setState({ selectedWord: word.text })
              }}
            />
          )}
        </div>
        {/* end .main-content */}

        {this.state.displayOnboarding && (
          <OnboardingModal
            host={this.props.officeInfo.host}
            app={this.props.app}
            i18n={this.i18n}
            handleClick={() => {
              const { lang } = this
              const { host } = this.props.officeInfo
              let onboarded = this.props.propertiesService.getLocal(
                'onboarded'
              ) as [UILanguage, HostType][] | null
              if (
                onboarded === null ||
                !Array.prototype.isPrototypeOf(onboarded)
              ) {
                onboarded = []
              }
              onboarded.push([lang, host])

              this.props.propertiesService.setLocal({ onboarded })
              this.setState({ displayOnboarding: false })
            }}
          />
        )}
      </div>
    ) /* end #spellchecker */
  }
}
