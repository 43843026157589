import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { messageParent } from '@oribi/office-js'
import { loginRedirect, logOut } from '@oribi/auth'
import Translator from '../api/i18n'
import { Dimmer, Loader as SemanticUiLoader, Icon } from 'semantic-ui-react'
import { determineApp, getAppManifest, OribiApp } from '../app'

const app = determineApp(window.location.host) as OribiApp
const i18n = new Translator(undefined, app)
const { aadClientId } = getAppManifest(app)

// Loader Content Component
const Loader: FC<{ content: string }> = ({ content }) => (
  <Dimmer inverted active>
    <SemanticUiLoader inverted active>
      {content}
    </SemanticUiLoader>
  </Dimmer>
)

const LogIn = () => {
  useEffect(() => {
    Office.onReady().then(() => {
      loginRedirect({
        clientId: aadClientId,
        onSuccess: response => {
          messageParent(response)
        },
        onError: error => {
          messageParent(error.errorMessage)
        }
      })
    })
  })

  return <Loader content={i18n.getMessage('status_logging_in')} />
}

const LogOut = () => {
  localStorage.removeItem('loggedIn')
  useEffect(() => {
    Office.onReady().then(() => {
      logOut(aadClientId, window.location.origin + '/dialog')
    })
  })

  return <Loader content='' />
}

const LogOutComplete = () => {
  useEffect(() => {
    Office.onReady().then(() => {
      // No particular message. Any message at all tells the parent
      // page to close the dialog.
      messageParent('')
    })
  })

  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <Dimmer active>
        <Icon name='check' size='big' />
      </Dimmer>
    </div>
  )
}

export default () => {
  // Check url param to determine which part of auth flow to run
  // /dialog closes dialog
  // /dialog/login signs user in
  // /dialog/logout signs user out
  const { id } = useParams<{ id: string }>()

  const AuthFlow = () =>
    id === 'login' ? (
      <LogIn />
    ) : id === 'logout' ? (
      <LogOut />
    ) : (
      <LogOutComplete />
    )

  return <AuthFlow />
}
