import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { determineAppToRun } from './app'
import AppSwitcher from './Sidebar/AppSwitcher'
import Startup from './Sidebar/Startup/'
import './style.scss'
import { FatalErrorMessage, FatalError, ErrorType } from './Sidebar/Errors'
import PropertiesService from './api/storage'
import { OribiApp } from './app'
import Dialog from './Dialog'
import { HostContext, HostProvider } from './context/HostContext'
import { HostType } from './types'
import { Dimmer, Loader } from 'semantic-ui-react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { app } = determineAppToRun()
if (app !== undefined && app !== null) {
  Sentry.init({
    dsn:
      'https://fc4800b5404a4bafb4cedc51315f36ee@o1180197.ingest.sentry.io/6292841',
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0 : 1,
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
    release: `${app.toString()}@${process.env.npm_package_version}`
  })
}

const Sidebar = () => {
  let isLocalStorageAvailable = new PropertiesService(OribiApp.STAVA_REX)
    .isLocalStorageAvailable

  if (!isLocalStorageAvailable) {
    return (
      <FatalErrorMessage error={new FatalError(ErrorType.NO_LOCALSTORAGE)} />
    )
  }

  const { app, displaySwitcher } = determineAppToRun()

  if (app === null) {
    return <FatalErrorMessage error={new FatalError(ErrorType.APP_IS_NULL)} />
  }

  if (app === undefined) {
    return <AppSwitcher />
  } else {
    return (
      <Startup
        app={app}
        enableAppSwitcher={displaySwitcher}
        loginDisabled={true}
      />
    )
  }
}

const LoadingAnimation = ({ content }: { content?: string }) => (
  <Dimmer page active>
    <Loader active={true} content={content} />
  </Dimmer>
)

const Host: FC = ({ children }) => (
  <HostProvider>
    <HostContext.Consumer>
      {({ host, errors }) => {
        if (host === HostType.Unknown) return <LoadingAnimation />

        if (errors.length || host === HostType.Other) {
          const error = new FatalError(ErrorType.NO_OFFICE, errors.join('\r\n'))
          return <FatalErrorMessage error={error} />
        }

        return children
      }}
    </HostContext.Consumer>
  </HostProvider>
)

// Run Office.initialize once just in case...
// https://github.com/OfficeDev/office-js/issues/246#issuecomment-437260860
Office.initialize = function (reason) {
  console.log('Office initialized', { reason })
}

// Render startup flow
ReactDOM.render(
  <Host>
    <Router>
      <Switch>
        <Route path={['/dialog/:id', '/dialog']}>
          <Dialog />
        </Route>
        <Route path='/'>
          <Sidebar />
        </Route>
      </Switch>
    </Router>
  </Host>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
