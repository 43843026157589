import React, { Component } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FatalError, FatalErrorMessage, ErrorType } from '../Errors'
import Main from '../Main'
import { User, OribiApp } from '../../types'
import { checkUserLicense } from '../Auth/helpers'
import PropertiesService, {
  LicenseType,
  UserProperties
} from '../../api/storage'
import LicenseKeyModal from './LicenseKeyModal'
import Translator from '../../api/i18n'
import { getOribiSpeakId } from '../TTS/api'
import { BrowserInfo, getBrowserInfo, Browser } from '../../api/browserInfo'
import { getAppSlug, getGA4Config, getSourceLang } from '../../app'
import LoginModal from './LoginModal'
import SchoolIdModal from './SchoolIdModal'
import { getRuntime, Runtime } from '../../api/runtime'
import { requestGraphUser } from '@oribi/auth'
import * as Sentry from '@sentry/react'
import { GA4Config, trackEvent } from '../../api/ga4'

interface Props {
  app: OribiApp
  enableAppSwitcher?: boolean
  loginDisabled: boolean
}

interface State {
  user: User
  lastError: FatalError | undefined
  statusText: string
  displayLoginBtn: boolean
  licenseMessage: string
  onLine: boolean
  oribiSpeakExtensionId: string
  license: LicenseType
  trial_start?: string // Date().toJSON()
  trial_expired?: boolean
  school_id?: number
  license_key?: string
  log: string[]
  speechSynthesisVoice?: SpeechSynthesisVoice
}

// const { Office } = window
function _isArray(input: any) {
  return Array.prototype.isPrototypeOf(input)
}

export default class Startup extends Component<Props, State> {
  propertiesService: PropertiesService
  i18n: Translator
  browserInfo: BrowserInfo
  runtime: Runtime
  isTeams: boolean
  ga4config: GA4Config | null

  constructor(props: Props) {
    super(props)
    const { app } = props

    this.ga4config = getGA4Config(app)

    this.propertiesService = new PropertiesService(app)
    this.i18n = new Translator(undefined, app)
    this.browserInfo = getBrowserInfo()
    this.runtime = getRuntime(this.propertiesService)
    this.isTeams = this.browserInfo.browser === Browser.TEAMS

    document.documentElement.lang = this.i18n.lang
    document.documentElement.dataset.app = getAppSlug(app)

    let lastError
    if (!navigator.onLine) {
      lastError = new FatalError(ErrorType.OFFLINE)
    }

    this.state = {
      user: {
        email: '',
        id: ''
      },
      lastError: lastError,
      statusText: this.i18n._('status_starting', [props.app]),
      displayLoginBtn: false,
      licenseMessage: '',
      onLine: navigator.onLine,
      oribiSpeakExtensionId: '',
      license: LicenseType.UNKNOWN,
      trial_start: undefined,
      trial_expired: false,
      school_id: undefined,
      license_key: undefined,
      log: []
    }

    this.onLoggedIn = this.onLoggedIn.bind(this)
  }

  async componentDidMount() {
    if (this.ga4config !== null) {
      trackEvent(this.ga4config)
    }

    // Add event listener offline to detect network loss.
    window.addEventListener('offline', () => this.setState({ onLine: false }))
    window.addEventListener('online', () => this.setState({ onLine: true }))

    this.initTTS()

    this.setState({
      statusText: this.i18n._('signing_in')
    })

    if (this.isTeams || this.props.loginDisabled) {
      this.onLoggedIn({
        id: '',
        email: ''
      })
      return
    }

    // If simulated user is set, skip actual sign in in flow and use stored user
    const simulatedUser = this.propertiesService.getLocal('simulatedUser')
    if (simulatedUser !== null) {
      return this.onLoggedIn(simulatedUser as User)
    }

    // Look for accessToken in localStorage
    const storedAccessToken = this.propertiesService.getLocal('msalAccessToken')
    const accessToken = (storedAccessToken || '') as string

    // Try to get graph user with cached accessToken
    const graphResponse = await requestGraphUser(accessToken)
    if (!graphResponse.success || !graphResponse.user) {
      this.setState({ displayLoginBtn: true })
      return
    }

    const { user } = graphResponse
    this.onLoggedIn(user)
    return // Done signing in
  }

  async onLoggedIn(user: User) {
    this.setState({ user })

    const { id, email } = user

    Sentry.setUser({ email, id })
    this.setState({ statusText: this.i18n._('status_getting_settings') })

    // Listen for storage change events
    this.propertiesService.addOnChangedListener(({ property, newValue }) => {
      // Make sure new values are correctly typed
      switch (property) {
        case 'license':
          this.setState({
            license: newValue as LicenseType
          })
          break
        case 'trial_start':
          this.setState({
            trial_start: !!newValue ? newValue.toString() : undefined
          })
          break
        case 'trial_expired':
          this.setState({
            trial_expired: !!newValue
          })
          break
        case 'school_id':
          this.setState({
            school_id: Number(newValue)
          })
          break
        case 'license_key':
          this.setState({
            license_key: !!newValue ? newValue.toString() : undefined
          })
          break
        default:
          break
      }
    })

    // Initiate properties service (DB storage)
    const storage = await this.propertiesService.init(id)

    // Determine if we want to import store from legacy version of app
    const did_import_old_store = this.propertiesService.getLocal(
      'did_import_old_store'
    )

    if (!did_import_old_store) {
      type OldSettings = { [key: string]: unknown }

      // Get localStorage from legacy app using iframe hack
      const oldUserSettings: UserProperties = await new Promise(resolve => {
        const oldUserProperties: UserProperties = {}
        const oldOrigin = 'https://office-add-ins.oribisoftware.com'
        const iframe = document.createElement('iframe')
        iframe.src = `${oldOrigin}/_${getAppSlug(this.props.app)}`
        iframe.style.display = 'none'
        let didResolve = false

        const timeout = setTimeout(() => {
          _done()
        }, 1000)

        function _done() {
          clearTimeout(timeout)
          if (document.body.contains(iframe)) {
            document.body.removeChild(iframe)
          }
          if (didResolve) return
          didResolve = true
          resolve(oldUserProperties)
        }

        document.body.appendChild(iframe)

        window.addEventListener('message', ({ origin, data }) => {
          // console.log('message', { origin, data })
          if (origin !== oldOrigin) return
          const { userStorage } = data
          if (userStorage !== undefined) {
            for (const key in userStorage as OldSettings) {
              const value = userStorage[key]

              switch (key) {
                case 'nowarns':
                case 'userwords':
                  if (Array.prototype.isPrototypeOf(value)) {
                    oldUserProperties[key] = value
                  }
                  break
                case 'grammar':
                case 'homophones':
                case 'spaces':
                case 'sentences':
                  if (value === 0 || value === 1) {
                    oldUserProperties[key] = Boolean(value)
                  }
                  break
                case 'listsize':
                  if (value === 1) {
                    oldUserProperties.listsize = '1'
                  } else if (value === 2) {
                    oldUserProperties.listsize = '2'
                  } else if (value === 3) {
                    oldUserProperties.listsize = '3'
                  }
                  break
                default:
                  break
              }
            }
          }
          _done()
        })
      })

      // Update database with old settings if database hasn't already been
      // updated with corresponding setting
      for (const key in oldUserSettings) {
        const storageKey = key as keyof UserProperties
        const legacyValue = oldUserSettings[storageKey]
        const currentValue = storage[storageKey]
        let newValue

        // Use JSON.stringify to compare arrays as well
        if (JSON.stringify(legacyValue) === JSON.stringify(currentValue)) {
          // Do nothing if values match exactly
          continue
        } else if (_isArray(legacyValue) && _isArray(currentValue)) {
          // Concatenate arrays (userwords and nowarns)

          newValue = [
            ...(legacyValue as string[]),
            ...(currentValue as string[])
          ].filter((value, i, array) => {
            const isDuplicate = array.slice(0, i).includes(value)
            return !isDuplicate
          })
        } else if (this.propertiesService.isDefault(storageKey, currentValue)) {
          // Set new value to legacy value if current value is default (not changed)
          newValue = legacyValue
        }

        this.propertiesService.set({ [storageKey]: newValue })
      }

      this.propertiesService.setLocal({
        did_import_old_store: true
      })
    }

    // Check User License...
    let { license, trial_start, school_id, license_key } = storage

    if (license === undefined) license = LicenseType.UNKNOWN
    const { app } = this.props

    this.setState({ trial_start, school_id, license_key })

    const licenseInfo = await checkUserLicense({
      user,
      app,
      trialStart: trial_start,
      schoolId: school_id,
      licenseType: license,
      licenseKey: license_key,
      handleStatusChange: statusText => this.setState({ statusText }),
      handleTrialExpired: () => {
        this.propertiesService.set({ trial_expired: true })
      },
      i18n: this.i18n._
    })

    const { type, message } = licenseInfo

    license = type
    this.propertiesService.set({ license }, false)

    let licenseMessage: string
    if (!message && this.isTeams && license === LicenseType.FREE) {
      licenseMessage = this.i18n._('onenote_teams_license_free', [app])
    } else if (
      !message &&
      this.props.loginDisabled &&
      license === LicenseType.FREE
    ) {
      licenseMessage = this.i18n._('temp_license_free', [app])
    } else {
      licenseMessage = message || ''
    }

    const statusText = ''
    this.setState({
      licenseMessage,
      license,
      statusText
    })
  }

  initTTS = async () => {
    const oribiSpeakExtensionId = await getOribiSpeakId()
    this.setState({ oribiSpeakExtensionId })

    // If Oribi Speak isn't installed, see if local TTS is available
    if (!oribiSpeakExtensionId && 'speechSynthesis' in window) {
      const { speechSynthesis } = window
      const voices: SpeechSynthesisVoice[] = await new Promise(resolve => {
        let timesChecked = 0
        const checker = setInterval(() => {
          const v = speechSynthesis.getVoices()
          if (v.length > 0 || timesChecked >= 10) {
            clearInterval(checker)
            resolve(v)
          }
          timesChecked++
        }, 10)
      })

      // Look for a voice in the same language as suggestions
      const sourceLang = getSourceLang(this.props.app)
      const voice = voices.find(
        voice => voice.lang.split('-')[0] === sourceLang
      )
      if (voice)
        this.setState({
          speechSynthesisVoice: voice
        })
    }
  }

  log = (...args: any[]) => {
    this.setState(prevState => {
      const string = Date.now() + ': ' + args.join(' ')
      prevState.log.splice(0, 0, string)
      return prevState
    })
    console.log(...args)
  }

  render() {
    const { app, enableAppSwitcher } = this.props
    const {
      user,
      lastError,
      statusText,
      displayLoginBtn,
      licenseMessage,
      license: licenseType = LicenseType.UNKNOWN,
      trial_expired: trialExpired,
      trial_start: trialStart,
      license_key: licenseKey
    } = this.state

    if (lastError !== undefined)
      return <FatalErrorMessage app={app} error={lastError} />

    const isLicensed = [
      LicenseType.DOMAIN,
      LicenseType.FREE,
      LicenseType.LICENSE_KEY,
      LicenseType.SCHOOL,
      LicenseType.TRIAL
    ].includes(licenseType)

    const isLoggedIn = () => {
      if (this.isTeams || this.props.loginDisabled) return true
      return !!user.id && !!user.email
    }
    const isReady = isLoggedIn() && isLicensed
    const i18n = this.i18n._

    if (isReady)
      return (
        <Main
          app={app}
          user={user}
          propertiesService={this.propertiesService}
          enableAppSwitcher={!!enableAppSwitcher}
          licenseMessage={licenseMessage}
          i18n={this.i18n}
          currentLang={this.i18n.lang}
          oribiSpeakExtensionId={this.state.oribiSpeakExtensionId}
          speechSynthesisVoice={this.state.speechSynthesisVoice}
        />
      )

    // If not ready yet, display dimmer or modal
    if (displayLoginBtn)
      return (
        <LoginModal
          app={app}
          enableSimulator={!!enableAppSwitcher}
          storage={this.propertiesService}
          i18n={i18n}
          onSuccess={user => {
            this.setState({ displayLoginBtn: false })
            this.onLoggedIn(user)
          }}
          onError={error => {
            this.setState({ lastError: error })
          }}
        />
      )

    if (licenseType === LicenseType.GREYLIST)
      return (
        <SchoolIdModal
          enableAppSwitcher={!!enableAppSwitcher}
          app={app}
          user={user}
          onSuccess={(id, message) => {
            this.propertiesService.set({
              license: LicenseType.SCHOOL,
              school_id: id
            })

            this.setState({ licenseMessage: message })
          }}
          onFail={() => {
            this.propertiesService.set({ license: LicenseType.UNAUTHORIZED })
          }}
          i18n={i18n}
          storage={this.propertiesService}
        />
      )

    if (licenseType === LicenseType.UNAUTHORIZED)
      return (
        <LicenseKeyModal
          app={app}
          user={user}
          trialExpired={!!trialExpired}
          trialStart={trialStart}
          licenseKey={licenseKey}
          translator={this.i18n}
          propertiesService={this.propertiesService}
          onSuccess={(key: string, message: string) => {
            this.setState({ licenseMessage: message })
            this.propertiesService.set({
              license_key: key,
              license: LicenseType.LICENSE_KEY
            })
          }}
          onTrialActivated={message => {
            this.setState({ licenseMessage: message })
          }}
        />
      )

    // If loading, display dimmer
    return (
      <Dimmer page active={!isReady}>
        <Loader active={true} content={statusText} />
      </Dimmer>
    )
  }
}
