export enum HostType {
  Word = 'Word',
  OneNote = 'OneNote',
  Other = 'Unsupported editor',
  Unknown = 'Host not yet determined'
}

export enum PlatformType {
  PC = 'PC',
  OfficeOnline = 'OfficeOnline',
  Mac = 'Mac',
  iOS = 'iOS',
  Android = 'Android',
  Universal = 'Universal',
  Unknown = 'Platform not yet determined'
}

export interface OfficeInfo {
  host: HostType
  platform: PlatformType
}

export interface User {
  email: string
  id: string
}

export { OribiApp } from './app'
