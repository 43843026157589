import React, { useState } from 'react'
import { OribiApp } from '../types'
import Startup from './Startup/'
import { Container, Button, Image } from 'semantic-ui-react'
import { getIconUrl } from '../app'

export default () => {
  const [app, setApp] = useState(undefined as OribiApp | undefined)

  if (app !== undefined) {
    return <Startup app={app} enableAppSwitcher={true} loginDisabled={true} />
  }

  const AppButton = ({ app }: { app: OribiApp }) => (
    <Image
      fluid
      as='a'
      href='#'
      onClick={(e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        localStorage.oribiAppToRun = app
        setApp(app)
      }}
      src={getIconUrl(app, { size: 256 })}
      centered
      label={app}
    />
  )

  const apps = [
    OribiApp.STAVA_REX,
    OribiApp.SPELLRIGHT,
    OribiApp.VERITYSPELL,
    OribiApp.STAVLET,
    OribiApp.SCHREIBREX
  ]

  return (
    <Container className='app-switcher'>
      <h1>Välj program</h1>

      <div className='app-switcher-grid'>
        {apps.map((app, i) => (
          <AppButton key={i} app={app} />
        ))}
      </div>
    </Container>
  )
}

export const AppSwitcherTrigger = () => {
  return (
    <Button
      icon='exchange'
      content='Växla program'
      onClick={() => {
        delete localStorage.oribiAppToRun
        window.location.reload()
      }}
    />
  )
}
