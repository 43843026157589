import React, { FC, useContext, useState } from 'react'
import { User, OribiApp } from '../types'
import PropertiesService from '../api/storage'
import { Menu, Icon, MenuItemProps } from 'semantic-ui-react'
import SpellChecker from './SpellChecker'
import Dictionary from './Dictionary'
import Settings from './Settings'
import Translator, { MessageGetter, UILanguage } from '../api/i18n'
import { hasDictionary } from '../app'
import SelectedWordProvider, {
  SelectedWordContext
} from './context/SelectedWord'
import { HostContext } from '../context/HostContext'

interface Props {
  app: OribiApp
  user: User
  enableAppSwitcher: boolean
  propertiesService: PropertiesService
  licenseMessage: string
  i18n: Translator
  currentLang: UILanguage
  oribiSpeakExtensionId: string
  speechSynthesisVoice?: SpeechSynthesisVoice
}

// Panes, zero-based index
enum Pane {
  SPELLCHECKER = 'spellchecker',
  DICTIONARY = 'dictionary',
  SETTINGS = 'settings'
}

export default (props: Props) => {
  const i18n: MessageGetter = props.i18n.getMessage

  const [activePane, setActivePane] = useState(Pane.SPELLCHECKER)
  const handleMenuItemClick = (name: Pane) => {
    if (activePane !== name) setActivePane(name)
  }

  const { app, user, enableAppSwitcher, propertiesService } = props
  const isDictionaryAvailable = hasDictionary(app)

  const { host, platform } = useContext(HostContext)
  const officeInfo = { host, platform }

  const menuItems = [
    {
      pane: Pane.SPELLCHECKER,
      icon: 'spell check',
      label: i18n('spellchecker_header')
    },
    {
      pane: Pane.SETTINGS,
      icon: 'settings',
      label: i18n('settings_header')
    }
  ]

  if (isDictionaryAvailable)
    menuItems.splice(1, 0, {
      pane: Pane.DICTIONARY,
      icon: 'book',
      label: i18n('dictionary_header')
    })

  interface TabPanelProps {
    pane: Pane
    grey?: boolean
    branded?: boolean
  }
  const TabPanel: React.FC<TabPanelProps> = props => {
    const { pane, children } = props

    const style: { background?: string } = {}
    if (!!props.grey) {
      style.background = '#f4f4f4'
    }

    let className = 'tab-view-panel'
    if (!!props.branded) className += ' branded'

    return activePane === pane ? (
      <div className={className} style={style}>
        {children}
      </div>
    ) : (
      <></>
    )
  }

  return (
    <SelectedWordProvider>
      <div className='tab-view'>
        <TabPanel pane={Pane.SPELLCHECKER}>
          <SelectedWordContext.Consumer>
            {({ word, setWord }) => {
              return (
                <SpellChecker
                  i18n={props.i18n}
                  app={props.app}
                  officeInfo={officeInfo}
                  propertiesService={props.propertiesService}
                  oribiSpeakExtensionId={props.oribiSpeakExtensionId}
                  speechSynthesisVoice={props.speechSynthesisVoice}
                  setSelectedWord={(newWord: string) => {
                    if (setWord && word !== newWord) setWord(newWord)
                  }}
                />
              )
            }}
          </SelectedWordContext.Consumer>
        </TabPanel>
        {isDictionaryAvailable && (
          <TabPanel pane={Pane.DICTIONARY}>
            <Dictionary />
          </TabPanel>
        )}
        <TabPanel branded pane={Pane.SETTINGS}>
          <Settings
            app={app}
            user={user}
            officeInfo={officeInfo}
            propertiesService={propertiesService}
            enableAppSwitcher={enableAppSwitcher}
            licenseMessage={props.licenseMessage}
            i18n={props.i18n}
            currentLang={props.currentLang}
          />
        </TabPanel>

        <Menu
          fluid
          borderless
          fixed='bottom'
          widths={menuItems.length as any}
          style={{
            display: 'flex'
          }}
        >
          {menuItems.map(({ pane, icon, label }) => {
            const isActive = pane === activePane

            return (
              <Menu.Item
                key={pane}
                name={pane}
                active={isActive}
                onClick={(
                  _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                  data: MenuItemProps
                ) => {
                  const { name } = data
                  if (name) handleMenuItemClick(name as Pane)
                }}
                style={{
                  flexGrow: 1,
                  width: 'auto'
                }}
                title={label}
              >
                {isActive && label}
                {!isActive && <Icon name={icon as any} />}
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    </SelectedWordProvider>
  )
}
