import PropertiesService from '../api/storage'
import { version } from '../../package.json'
// import * as serviceWorker from './serviceWorker'

enum RuntimeType {
  INSTALL = 'install', // first time running the app
  UPDATE = 'update', // first time running this version
  NORMAL = 'normal' // user has run this version before
}
export interface Runtime {
  type: RuntimeType
  version: string
  previousVersion?: string
}

export const getRuntime = (storage: PropertiesService): Runtime => {
  const runtime: Runtime = {
    type: RuntimeType.NORMAL,
    version: version
  }

  const previousVersion = storage.getLocal('previousVersion')

  if ( previousVersion === null ) {
    runtime.type = RuntimeType.INSTALL
  } else if ( typeof previousVersion === 'string' && previousVersion !== version ) {
    runtime.type = RuntimeType.UPDATE
    runtime.previousVersion = previousVersion
  }

  storage.setLocal({ previousVersion: version })
  
  return runtime
}