import './styles.scss'
import React, { Component } from 'react'
import {
  Container,
  Accordion,
  Form,
  CheckboxProps,
  DropdownProps,
  TextAreaProps,
  Loader,
  Dimmer,
  Button,
  Icon
} from 'semantic-ui-react'
import { OribiApp, User, OfficeInfo } from '../../types'
import PropertiesService, {
  UserProperties,
  // LicenseType,
  StorageChangeCallback
} from '../../api/storage'
import { AppSwitcherTrigger } from '../AppSwitcher'
import LogOutBtn from '../Auth/LogOutBtn'
import Translator, { MessageGetter, UILanguage } from '../../api/i18n'
import { FirstLangSwitcher, UILangSwitcher } from './LangSwitchers'
import { version } from '../../../package.json'
import ClearSettingsBtn from './ClearSettingsBtn'
import { getIconUrl, getManualUrl } from '../../app'
import { Browser, BrowserInfo, getBrowserInfo } from '../../api/browserInfo'
import WordList from './WordList'

interface Props {
  app: OribiApp
  user: User
  officeInfo: OfficeInfo
  propertiesService: PropertiesService
  enableAppSwitcher: boolean
  licenseMessage?: string
  i18n: Translator
  currentLang: UILanguage
}

interface State {
  storage: UserProperties
  loading: boolean
  displayUserId: boolean
}

export default class Settings extends Component<Props, State> {
  // timeout?: NodeJS.Timeout
  i18n: MessageGetter

  constructor(props: Props) {
    super(props)
    const { propertiesService } = props

    this.i18n = props.i18n.getMessage

    this.state = {
      storage: propertiesService.get(null),
      loading: true,
      displayUserId: false
    }
  }

  componentDidMount = async () => {
    const storage = this.props.propertiesService.get(null)
    this.setState({ storage, loading: false })

    // this.timeout = setTimeout(this.testProps, 3000)
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)

    // Listen for storage change events
    this.props.propertiesService.addOnChangedListener(this.handlePropertyChange)
  }

  componentWillUnmount = () => {
    // if ( this.timeout !== undefined ) clearTimeout(this.timeout)
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Alt') this.setState({ displayUserId: true })
  }
  handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Alt') this.setState({ displayUserId: false })
  }

  handlePropertyChange: StorageChangeCallback = ({ property, newValue }) => {
    this.setState(({ storage }) => {
      // @ts-ignore
      storage[property] = newValue
      return { storage }

      /*
      // Make sure new values are correctly typed?
      switch ( property ) {
        case 'license':
          storage.license = newValue as LicenseType
          break
        // Booleans
        case 'trial_expired':
        case 'homophones':
          storage[property] = !!newValue
          break
        case 'school_id':
          storage.school_id = Number(newValue)
          break
        case 'license_key':
        case 'trial_start':
          storage[property] = !!newValue ? newValue.toString() : undefined
          break
        default: break
      }

      return ({ storage })
      */
    })
  }

  handleCheckboxChange = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const { checked, id } = data
    if (id === undefined) return

    if (id in this.state.storage) {
      this.props.propertiesService.set({
        [id]: checked
      })
    }
  }

  handleDropdownChange = (
    _event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    // const value = data.value as FirstLang
    const { value, id } = data
    if (id === undefined) return

    if (id in this.state.storage) {
      this.props.propertiesService.set({
        [id]: value
      })
    }
  }

  handleRadioChange = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const { value, name } = data
    if (name === undefined) return

    if (name in this.state.storage) {
      this.props.propertiesService.set({
        [name]: value
      })
    }
  }

  handleTextAreaInput = (
    _event: React.FormEvent<HTMLTextAreaElement>,
    data: TextAreaProps
  ) => {
    const { value, id } = data
    if (id === undefined) return

    if (id in this.state.storage) {
      this.setState(prevState => {
        // @ts-ignore
        prevState.storage[id] = value.split('\n')

        return prevState
      })
    }
  }

  handleTextAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const stringToArray = (string: string): string[] => {
      return string
        .split('\n')
        .map(word => word.trim()) // Trim whitespace from every word
        .filter((word, i, words) => {
          if (!word) return false

          return !words.slice(0, i).includes(word)
        }) // Filter out empty strings and duplicates
    }

    const {
      currentTarget: { id, value }
    } = event
    if (id === undefined) return

    if (id in this.state.storage) {
      this.props.propertiesService.set({
        [id]: stringToArray(value)
      })
    }
  }

  render = () => {
    const {
      app,
      user,
      officeInfo,
      propertiesService,
      enableAppSwitcher,
      // licenseMessage,
      currentLang
    } = this.props
    const { storage } = this.state
    const { i18n } = this

    // const licenseType = storage.license
    // const { license_key } = storage

    // Message color based on license type
    // let warning = false,
    //   positive = false,
    //   negative = false

    // switch (licenseType) {
    //   case LicenseType.DOMAIN:
    //   case LicenseType.LICENSE_KEY:
    //   case LicenseType.SCHOOL:
    //     positive = true
    //     break
    //   case LicenseType.GREYLIST:
    //   case LicenseType.TRIAL:
    //     warning = true
    //     break
    //   case LicenseType.UNAUTHORIZED:
    //   case LicenseType.UNKNOWN:
    //     negative = true
    //     break
    //   default:
    //     if (user.email.endsWith('@oribi.se')) positive = true
    //     break
    // }

    // Special settings apply for English spellcheckers
    const isOlsEnglish =
      app === OribiApp.SPELLRIGHT || app === OribiApp.VERITYSPELL

    let listSizeOptions = []
    switch (app) {
      case OribiApp.SPELLRIGHT:
        listSizeOptions = [
          { label: i18n('settings_wordlist_listsize_large'), value: '3' },
          { label: i18n('settings_wordlist_listsize_medium'), value: '2' },
          { label: i18n('settings_wordlist_listsize_small'), value: '1' }
        ]
        break
      case OribiApp.VERITYSPELL:
        listSizeOptions = [
          { label: 'Advanced', value: '3' },
          { label: 'Standard', value: '2' }
        ]
        break
      default:
        listSizeOptions = [
          { label: i18n('settings_wordlist_listsize_large'), value: '2' },
          { label: i18n('settings_wordlist_listsize_small'), value: '1' }
        ]
        break
    }

    const englishTypeOptions = [
      {
        label: i18n('settings_language_english_type_american'),
        value: 'american'
      },
      {
        label: i18n('settings_language_english_type_british'),
        value: 'british'
      }
    ]

    const CreateWordList = ({ list }: { list: 'userwords' | 'nowarns' }) => (
      <WordList
        words={storage[list] || []}
        list={list}
        app={app}
        i18n={i18n}
        handleChange={words => {
          this.props.propertiesService.set({ [list]: words })
        }}
      />
    )

    const accordionPanels = [
      {
        key: 'general',
        title: {
          content: i18n('settings_general_header'),
          icon: 'cog'
        },
        content: {
          content: (
            <Form>
              <Form.Checkbox
                checked={storage.homophones}
                id='homophones'
                label={i18n('settings_general_homophones_label')}
                onChange={this.handleCheckboxChange}
              />
              <Form.Checkbox
                checked={storage.spaces}
                label={i18n('settings_general_spaces_label')}
                id='spaces'
                onChange={this.handleCheckboxChange}
              />
              <Form.Checkbox
                checked={storage.grammar}
                label={i18n('settings_general_grammar_label')}
                id='grammar'
                onChange={this.handleCheckboxChange}
              />
              {isOlsEnglish && (
                <>
                  <Form.Checkbox
                    checked={storage.sentences}
                    label={i18n('settings_general_sentences_label')}
                    id='sentences'
                    onChange={this.handleCheckboxChange}
                  />
                  <Form.Field style={{ marginTop: '1em' }}>
                    <label>
                      {<p>{i18n('settings_language_english_type_header')}</p>}
                      {englishTypeOptions.map(({ label, value }) => (
                        <Form.Radio
                          name='english_type'
                          key={value}
                          label={label}
                          value={value}
                          checked={storage.english_type === value}
                          onChange={this.handleRadioChange}
                        />
                      ))}
                    </label>
                  </Form.Field>
                </>
              )}
              {/* <h2>{ i18n('settings_wordlist_header') }</h2> */}
              <Form.Field>
                <label>
                  {<p>{i18n('settings_wordlist_listsize_label')}</p>}
                  {listSizeOptions.map(({ label, value }) => (
                    <Form.Radio
                      name='listsize'
                      key={value}
                      label={label}
                      value={value}
                      checked={storage.listsize === value}
                      onChange={this.handleRadioChange}
                    />
                  ))}
                </label>
              </Form.Field>
            </Form>
          )
        }
      },
      (() => {
        if (app !== OribiApp.SPELLRIGHT) return undefined
        return {
          key: 'language',
          title: {
            content: i18n('settings_language_header'),
            icon: 'globe'
          },
          content: {
            content: (
              <>
                <FirstLangSwitcher
                  i18n={i18n}
                  value={storage.firstlang}
                  onChange={this.handleDropdownChange}
                />
                <UILangSwitcher
                  storage={this.props.propertiesService}
                  i18n={i18n}
                  currentLang={currentLang}
                />
              </>
            )
          }
        }
      })(),
      {
        key: 'userwords',
        title: {
          content: i18n('settings_wordlist_userwords_header'),
          icon: 'list'
        },
        content: {
          content: <CreateWordList list='userwords' />
        }
      },
      {
        key: 'nowarns',
        title: {
          content: i18n('command_ignore'),
          icon: 'clone'
        },
        content: {
          content: <CreateWordList list='nowarns' />
        }
      },
      // {
      //   key: 'license',
      //   title: {
      //     content: i18n('settings_license_header'),
      //     icon: 'key'
      //   },
      //   content: {
      //     content: (
      //       <>
      //         {!!licenseMessage && (
      //           <Message
      //             positive={positive}
      //             warning={warning}
      //             negative={negative}
      //           >
      //             {licenseMessage}
      //           </Message>
      //         )}

      //         {licenseType === LicenseType.TRIAL && (
      //           <ActivateBtn
      //             propertiesService={propertiesService}
      //             app={app}
      //             trialActive={true}
      //             i18n={i18n}
      //           />
      //         )}

      //         {licenseType === LicenseType.LICENSE_KEY && !!license_key && (
      //           <div>
      //             <p>
      //               {i18n('settings_license_key_is_x', [license_key])}
      //               {!license_key.startsWith('H') && (
      //                 <>
      //                   {' ' + i18n('settings_license_manage_at') + ' '}
      //                   <a
      //                     href={i18n('my_account_url')}
      //                     target='_blank'
      //                     rel='noopener noreferrer'
      //                   >
      //                     {i18n('my_account')}.
      //                   </a>
      //                 </>
      //               )}
      //             </p>
      //             <DeactivateBtn
      //               propertiesService={propertiesService}
      //               app={app}
      //               user={user}
      //               licenseKey={license_key}
      //               i18n={i18n}
      //             />
      //           </div>
      //         )}
      //       </>
      //     )
      //   }
      // },
      (() => {
        if (!user.id || !user.email) return undefined
        return {
          key: 'account',
          title: {
            content: i18n('settings_account_header'),
            icon: 'user'
          },
          content: {
            content: (
              <>
                {this.state.displayUserId && (
                  <small style={{ opacity: 0.7 }}>
                    {i18n('user_id_x', [user.id])}
                  </small>
                )}
                <LogOutBtn
                  storage={this.props.propertiesService}
                  label='top'
                  user={user}
                  i18n={i18n}
                  as='a'
                />
              </>
            )
          }
        }
      })(),
      {
        key: 'about',
        title: {
          content: i18n('settings_about_header', [app]),
          icon: 'info'
        },
        content: {
          content: (
            <>
              <p>
                {i18n('settings_about_system_information', [
                  app,
                  version,
                  officeInfo.host as string,
                  officeInfo.platform as string
                ])}{' '}
                <BrowserInfoView info={getBrowserInfo()} />
              </p>
              <p>
                <Button
                  basic
                  as='a'
                  content={i18n('show_welcome_screen')}
                  onClick={() => {
                    this.props.propertiesService.deleteLocal('onboarded')
                    window.location.reload()
                  }}
                />
              </p>
              <p>
                {i18n('settings_about_copyright', [
                  new Date().getFullYear().toString()
                ])}
                &nbsp;
                <a
                  href='https://oribi.se/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {i18n('settings_about_developer_credits', [app])}
                </a>
              </p>
              <p>
                <Button
                  fluid
                  icon='file'
                  primary
                  href={getManualUrl(app, this.props.i18n.lang)}
                  target='_blank'
                  content={i18n('manual_header', [app])}
                />
              </p>
            </>
          )
        }
      },
      (() => {
        if (!enableAppSwitcher) return undefined
        return {
          key: 'extras',
          title: {
            content: 'Extrafunktioner',
            icon: 'flask'
          },
          content: {
            content: (
              <>
                <p>
                  Du kör för tillfället en specialversion av {app} och har lite
                  fler rättigheter än vanligt.
                </p>
                <p>NODE_ENV: {process.env.NODE_ENV}</p>
                <Accordion
                  style={{ marginBottom: 16 }}
                  defaultActiveIndex={undefined}
                  panels={[
                    {
                      key: 'display-json',
                      title: 'Visa JSON',
                      content: {
                        content: (
                          <div>
                            <code
                              style={{
                                display: 'block',
                                whiteSpace: 'break-spaces',
                                marginBottom: 20
                              }}
                            >
                              {JSON.stringify(storage, null, 2)}
                            </code>
                            <ClearSettingsBtn
                              propertiesService={propertiesService}
                            />
                          </div>
                        )
                      }
                    }
                  ]}
                />
                <AppSwitcherTrigger />
              </>
            )
          }
        }
      })()
    ]

    return (
      <div className='tab-view-panel-scrolling-content'>
        <Container id='settings' style={{ paddingTop: 15 }}>
          {!!this.state.loading && (
            <Dimmer active inverted>
              <Loader active inverted />
            </Dimmer>
          )}

          <header>
            <img
              src={getIconUrl(app, { transparent: false })}
              alt={`${app} icon`}
            />
            <h1>{i18n('settings_header')}</h1>
          </header>

          <Accordion
            styled
            exclusive={true}
            defaultActiveIndex={0}
            style={{ marginTop: '1em' }}
            panels={accordionPanels}
          />
        </Container>
      </div>
    )
  }
}

export const BrowserInfoView = ({
  info: { browser, version }
}: {
  info: BrowserInfo
}) => {
  let iconName: 'chrome' | 'edge' | 'firefox' | 'safari' | 'users' | undefined

  switch (browser) {
    case Browser.CHROME:
      iconName = 'chrome'
      break
    case Browser.EDGE:
      iconName = 'edge'
      break
    case Browser.FIREFOX:
      iconName = 'firefox'
      break
    case Browser.SAFARI:
      iconName = 'safari'
      break
    case Browser.TEAMS:
      iconName = 'users'
      break
    default:
      break
  }

  return (
    <>
      {!!iconName && <Icon style={{ opacity: 0.67 }} name={iconName} />}
      {browser} version {version}.
    </>
  )
}
