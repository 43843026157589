
import React from 'react'
import { Button, Icon, Image, Modal, Transition } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import PropertiesService from '../../api/storage'
import { getIconUrl } from '../../app'
import {  OribiApp, User } from '../../types'
import { AppSwitcherTrigger } from '../AppSwitcher'
import LoginBtn, { SimulateLogIn } from '../Auth/LogInBtn'
import { FatalError } from '../Errors'

interface Props {
  app: OribiApp
  enableSimulator: boolean
  storage: PropertiesService
  i18n: MessageGetter
  onSuccess: (user: User) => void
  onError: (error: FatalError) => void
}

const LoginModal = (props: Props) => {
  const { app, storage, enableSimulator, i18n, onSuccess, onError } = props
  
  return (
    <Transition transitionOnMount animation='fade up'>
      <Modal open className='center' tabIndex='1'>
        <Modal.Header className='branded'>
          <Image src={ getIconUrl(app, { transparent: true }) } />
          <Icon name='sign in' />
          { i18n('log_in_header') }
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{ i18n('log_in_disclaimer', ['Microsoft', app])}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <LoginBtn
            i18n={ i18n }
            onSuccess={(user, accessToken) => {
              onSuccess(user)
              storage.setLocal({ msalAccessToken: accessToken })
            }}
            onError={error => {
              onError(error)
            }}
          />
          <p>{ i18n('log_in_new_window') }</p>
        </Modal.Actions>

        <Modal.Content>
          <p>
            { i18n('log_in_no_provider_account', ['Microsoft']) } <br />
            <a
              href='https://signup.live.com/signup'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'underline '}}
            >
              { i18n('log_in_get_provider_account') }
            </a>
          </p>
        </Modal.Content>

      { enableSimulator && 
        <Modal.Actions className='bottom'>
            <Button.Group vertical secondary fluid size='small'>
              <SimulateLogIn storage={ storage } onSuccess={ onSuccess } />
              <AppSwitcherTrigger />
            </Button.Group> 
        </Modal.Actions>
      }
      </Modal>
    </Transition>
  )
}

export default LoginModal