import React, { useEffect } from 'react'
import { createContext, useState, FC } from 'react'
import { HostType, PlatformType } from '../types'

type HostContextType = {
  host: HostType
  platform: PlatformType
  errors: string[]
}

export const defaultHostContext: HostContextType = {
  host: HostType.Unknown,
  platform: PlatformType.Unknown,
  errors: []
}

export const HostContext = createContext(defaultHostContext)

type RuntimeChecker = (host: HostType, platform: PlatformType) => string[]
const getRuntimeErrors: RuntimeChecker = (host, platform) => {
  const errors: string[] = []

  // Body.getRange requires WordApi 1.3...
  // https://github.com/OfficeDev/office-js-docs/blob/master/reference/requirement-sets/word-api-requirement-sets.md
  const isWord = host === HostType.Word
  const isWordUpdated = Office.context.requirements.isSetSupported(
    'WordApi',
    '1.3'
  )
  if (isWord && !isWordUpdated) {
    let minVersion

    switch (platform) {
      case PlatformType.PC:
        minVersion = '1612 (Build 7668.1000)'
        break
      case PlatformType.Mac:
        minVersion = '15.32'
        break
      case PlatformType.iOS:
        minVersion = '2.22'
        break
      default:
        minVersion = '2016'
        break
    }

    const message = `'error_word_api' ${minVersion}` // this.i18n._('error_word_api', [app, minVersion])
    errors.push(message)
  }

  const isOneNote = host === HostType.OneNote
  const isOneNoteUpdated = Office.context.requirements.isSetSupported(
    'OneNoteApi',
    '1.1'
  )
  if (isOneNote && !isOneNoteUpdated) {
    const message = 'error_onenote_api' // this.i18n._('error_onenote_api', [app])
    errors.push(message)
  }

  const isMacOS = platform === PlatformType.Mac
  const macOSInfo = navigator.userAgent.match(/OS\sX\s(\d\d_\d\d)/)
  if (isMacOS && !!macOSInfo) {
    // macOSInfo is either something like ["OS X 10_15", "10_15"] or null
    const macOSVersion = macOSInfo[1].replace('_', '.') // "10.15"
    const versionNumber = parseFloat(macOSVersion) // 10.15

    // Require macOS High Sierra or later
    if (versionNumber < 10.13) {
      const message = 'error_macos' // this.i18n._('error_macos', [app])
      errors.push(message)
    }
  }

  const safariInfo = navigator.appVersion.match(/Version\/(\d+\.?\S*)\sSafari/)
  if (!!safariInfo) {
    const safariVersion = safariInfo[1]
    const versionNumber = parseFloat(safariVersion)
    if (versionNumber < 11.1) {
      const message = 'error_safari' // this.i18n._('error_safari', [app])
      errors.push(message)
    }
  }

  return errors
}

export const HostProvider: FC = ({ children }) => {
  const [value, setValue] = useState(defaultHostContext)

  useEffect(() => {
    let { host, platform, errors } = defaultHostContext

    Office.onReady().then(office => {
      // Translate Office types into local types
      if (office.host === Office.HostType.Word) {
        host = HostType.Word
      } else if (office.host === Office.HostType.OneNote) {
        host = HostType.OneNote
      } else {
        host = HostType.Other
      }

      switch (office.platform) {
        case Office.PlatformType.PC:
          platform = PlatformType.PC
          break
        case Office.PlatformType.Mac:
          platform = PlatformType.Mac
          break
        case Office.PlatformType.OfficeOnline:
          platform = PlatformType.OfficeOnline
          break
        case Office.PlatformType.iOS:
          platform = PlatformType.iOS
          break
        default:
          platform = PlatformType.Universal
          break
      }

      let isDialog: boolean
      try {
        // isDialog becomes available at runtime but doesn't exist on Office.Context type
        // @ts-expect-error
        isDialog = Office.context.isDialog
      } catch (_error) {
        // Assume that ui is in dialog and skip runtime checks
        isDialog = true
      }

      // Only check runtime requirement sets if Office has loaded in taskpane
      if (office.host !== null && !isDialog)
        errors = getRuntimeErrors(host, platform)

      document.documentElement.dataset.host = host
      document.documentElement.dataset.platform = platform
      setValue({ host, platform, errors })
    })
  }, [])

  return <HostContext.Provider value={value}>{children}</HostContext.Provider>
}
