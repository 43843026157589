import React, { useState } from 'react'
import { Button, Message } from 'semantic-ui-react'
import PropertiesService from '../../api/storage'

interface Props {
  propertiesService: PropertiesService
}

const ClearSettingsBtn = (props: Props) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const { propertiesService } = props
  return <>
  { !!error && 
    <Message negative>{ error }</Message>
  }
    <Button
      loading={ loading }
      onClick={() => {
        setLoading(true)
        propertiesService.clear().then(result => {
          setLoading(false)
          if ( result.status === 'ok' ) {
            window.location.reload()
          } else {
            setError(result.msg || 'Något gick fel')
          }
        })
      }}
      fluid
      content={error ? 'Prova igen': 'Återställ inställningar' }
    />
  </>
}

export default ClearSettingsBtn