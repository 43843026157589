import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { requestGraphUser } from '@oribi/auth'
import { FatalError, ErrorType } from '../Errors'
import { User } from '../../types'
import { MessageGetter } from '../../api/i18n'
import PropertiesService from '../../api/storage'
import { displayDialogAsync } from '@oribi/office-js'

function _isUser(user: any): boolean {
  try {
    const keys = Object.keys(user)

    return (
      keys.length === 2 &&
      keys.includes('email') &&
      keys.includes('id') &&
      typeof user.email === 'string' &&
      ((typeof user.id === 'string' && user.email.length > 0) ||
        user.id === undefined ||
        user.id === null)
    )
  } catch {
    return false
  }
}

function _getUserFromAccessToken(
  accessToken: string
): Promise<User | undefined> {
  return new Promise(resolve => {
    requestGraphUser(accessToken)
      .then(({ success, user }) => {
        if (!success || !user) throw new Error('unsuccessfull graph request')

        const { id, email } = user
        if (!_isUser({ id, email })) throw new Error('invalid graph user')

        resolve({ id, email })
      })
      .catch(() => {
        resolve(undefined)
      })
  })
}

interface LogInBtnProps {
  onError: (error: FatalError) => void
  onSuccess: (user: User, accessToken: string) => void
  i18n: MessageGetter
}

const LoginBtn = (props: LogInBtnProps) => {
  const [loading, setLoading] = React.useState(false)

  const handleError = (error: FatalError | null) => {
    setLoading(false)
    if (error !== null) props.onError(error)
  }

  return (
    <Button
      disabled={loading}
      loading={loading}
      fluid
      primary
      content={props.i18n('log_in_with_provider', ['Microsoft'])}
      icon='microsoft'
      onClick={() => {
        setLoading(true)

        displayDialogAsync(window.location.origin + '/dialog/login', {
          onError: message => {
            handleError(new FatalError(ErrorType.LOGIN, message))
          },
          onMessage: (message, dialog) => {
            if (typeof message !== 'object') return

            if ('accessToken' in message) {
              const { accessToken } = message as { accessToken: string }
              // storage.setLocal('msalAccessToken', accessToken)
              dialog.close()

              _getUserFromAccessToken(accessToken).then(user => {
                props.onSuccess(user as User, accessToken)
              })
            } else {
              handleError(new FatalError(ErrorType.LOGIN, message))
            }
          },
          onClose: () => {
            handleError(new FatalError(ErrorType.LOGIN))
          }
        })
      }}
    />
  )
}

const validateEmail = (email: string) => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regExp.test(String(email).toLowerCase())
}

interface SimulateLogInProps {
  onSuccess: (user: User) => void
  storage: PropertiesService
  fluid?: boolean
}

export const SimulateLogIn = (props: SimulateLogInProps) => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')

  useEffect(() => {
    const input = document.getElementById('simulated-email')
    if (input) input.focus()
  })

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          fluid={props.fluid}
          secondary
          icon='spy'
          content='Simulera e-post'
        />
      }
    >
      <Modal.Header>Simulera e-post</Modal.Header>
      <Modal.Content>
        <p>
          Skriv in en e-postadress här om du vill testa licenshanteringen med
          ett annat konto.
        </p>
        <p>
          <strong>
            Observera att du inte kommer åt användarens inställningar och att
            denna funktion endast är tillgänglig i Rook.
          </strong>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Form
          onSubmit={() => {
            if (validateEmail(email)) {
              const user = { email: email, id: email }
              props.storage.setLocal({ simulatedUser: user })
              props.onSuccess(user)
            }
          }}
        >
          <Form.Input
            id='simulated-email'
            fluid
            placeholder='Ange e-postadress'
            value={email}
            action={{
              primary: true,
              icon: 'sign in'
            }}
            onChange={(_event, data) => {
              setEmail(data.value)
            }}
          />
        </Form>
      </Modal.Actions>
    </Modal>
  )
}

export default LoginBtn
