import { ErrorType, FatalError } from '../Sidebar/Errors'
import { HostType, OribiApp, User } from '../types'
import { UILanguage } from './i18n'

export enum LicenseType {
  UNAUTHORIZED = 'unauthorized', // Not authorized, input license key
  UNKNOWN = 'unknown', // Not known yet
  TRIAL = 'trial', // Trial active
  DOMAIN = 'domain', // Domain name is whitelisted
  GREYLIST = 'greylist', // Domain has license but only for valid schoolIds
  SCHOOL = 'school', // School ID has a valid license
  LICENSE_KEY = 'license_key', // EDD License key,
  FREE = 'no_license_needed' // Fallback if error
}

// Firstlang ≈ "modersmål"
export enum FirstLang {
  NULL = 0,
  SV = 1,
  DA = 2,
  NO = 3,
  FI = 4,
  DE = 5
}

const getDefaultFirstLang = (app: OribiApp): FirstLang => {
  // All apps but SpellRight enforce firstlang 0
  if (app !== OribiApp.SPELLRIGHT) return FirstLang.NULL

  let firstLang
  switch (navigator.language.slice(0, 2)) {
    case 'sv':
      firstLang = FirstLang.SV
      break
    case 'da':
      firstLang = FirstLang.DA
      break
    case 'no':
    case 'nn':
    case 'nb':
      firstLang = FirstLang.NO
      break
    case 'fi':
      firstLang = FirstLang.FI
      break
    case 'de':
      firstLang = FirstLang.DE
      break
    default:
      firstLang = FirstLang.NULL
      break
  }

  return firstLang
}

export interface UserProperties {
  userwords?: string[]
  nowarns?: string[]
  homophones?: boolean
  spaces?: boolean
  grammar?: boolean
  sentences?: boolean
  listsize?: '1' | '2' | '3'
  firstlang?: FirstLang
  english_type?: 'american' | 'british'
  license?: LicenseType
  trial_start?: string // Date().toJSON()
  trial_expired?: boolean
  school_id?: number
  license_key?: string
}

// Some props are better stored locally, thus not requiring a user ID. However,
// localStorage might not be available at all times, so leave this logic to the
// PropertiesService
export interface LocalProperties {
  uiLang?: UILanguage
  previousVersion?: string
  simulatedUser?: User
  msalAccessToken?: string
  onboarded?: [UILanguage, HostType][]
  did_import_old_store?: boolean
}
type setLocalFunction = (properties: LocalProperties) => void
type getLocalFunction = (
  key: keyof LocalProperties
) => LocalProperties[keyof LocalProperties] | null
type deleteLocalFunction = (key: keyof LocalProperties) => void

export interface StorageChange {
  property: PropKey
  newValue: PropValue
}
export type StorageChangeCallback = (detail: StorageChange) => void

type DefaultProperties = Complete<UserProperties>
export type PropKey = keyof UserProperties
export type PropValue = UserProperties[keyof UserProperties]

type initFunction = (userID: string) => Promise<UserProperties>
type getFunction = (prop: null | PropKey, ...rest: PropKey[]) => UserProperties
type getAsyncFunction = (
  prop: null | PropKey,
  ...rest: PropKey[]
) => Promise<UserProperties>
type registerFunction = (userID: string) => Promise<apiReturnValue>
type apiReturnValue = {
  status: 'ok' | 'failed'
  msg?: string
  userSettings?: UserProperties
  maxQuotaReached?: boolean // if message is "Request not allowed"
}

const isEmptyArray = (value: any) => {
  return Array.prototype.isPrototypeOf(value) && value.length === 0
}

export default class PropertiesService {
  app: OribiApp
  userID?: string
  target: EventTarget
  syncer?: NodeJS.Timeout
  queue: UserProperties[]
  isLocalStorageAvailable: boolean

  get appSlug(): string {
    return this.app.replace(/\s/g, '')
  }

  get localStoragePrefix(): string {
    return ['OribiApp', this.appSlug].join('_') + '_'
  }

  get localStorageUserPrefix(): string {
    if (!!this.userID) return this.localStoragePrefix

    return ['OribiApp', this.appSlug, this.userID].join('_') + '_'
  }

  get apiUrl(): string {
    return `https://user-settings.oribisoftware.com/api/${this.appSlug}`
  }

  readonly defaultPropertiesDesc: PropertyDescriptorMap = {
    userwords: {
      value: [],
      enumerable: true
    },
    nowarns: {
      value: [],
      enumerable: true
    },
    homophones: {
      value: true,
      enumerable: true
    },
    spaces: {
      value: true,
      enumerable: true
    },
    grammar: {
      value: true,
      enumerable: true
    },
    sentences: {
      value: true,
      enumerable: true
    },
    listsize: {
      value: '2',
      enumerable: true
    },
    firstlang: {
      value: FirstLang.NULL,
      enumerable: true,
      writable: true
    },
    english_type: {
      value: 'american',
      enumerable: true
    },
    license: {
      value: LicenseType.UNKNOWN,
      enumerable: true
    },
    trial_start: {
      value: undefined,
      enumerable: true
    },
    trial_expired: {
      value: false,
      enumerable: true
    },
    school_id: {
      value: undefined,
      enumerable: true
    },
    license_key: {
      value: undefined,
      enumerable: true
    }
  }
  private defaults = Object.defineProperties(
    {},
    this.defaultPropertiesDesc
  ) as DefaultProperties

  // Cache should match defaults but be writeable
  private cachePropertiesObject = Object.keys(
    this.defaultPropertiesDesc
  ).reduce((propsObject: PropertyDescriptorMap, propKey) => {
    propsObject[propKey] = Object.assign(
      {
        writable: true
      },
      this.defaultPropertiesDesc[propKey]
    )

    return propsObject
  }, {})

  // A writable copy of this.defaults. Get and set functions will write to this
  // object
  private cache: UserProperties = Object.create(
    this.defaults,
    this.cachePropertiesObject
  )

  constructor(app: OribiApp) {
    this.target = document
    this.app = app
    this.defaults.firstlang = getDefaultFirstLang(app)
    this.queue = []

    if (app === OribiApp.VERITYSPELL) {
      console.log('set listsize to 3 here')
    }

    try {
      localStorage.getItem('')
      this.isLocalStorageAvailable = true
    } catch (_error) {
      this.isLocalStorageAvailable = false
    }
  }

  init: initFunction = async (userID: string) => {
    if (!!userID) {
      this.userID = userID
      this.syncer = this.startSyncer()

      let allProperties = await this.getAsync(null)
      // Successful response but no data means non existent database row. User
      // must be created and defaults will remain in cache
      if (Object.keys(allProperties).length === 0) {
        await this.registerUser(userID)
        allProperties = this.defaults
      } else {
        // Otherwise, update cache with stored properties
        Object.assign(this.cache, allProperties)
      }
    } else {
      // If no user ID
      let allProperties = this.get(null)
      for (const x in this.cache) {
        const key = x as PropKey
        let value: PropValue = this.cache[key]

        // Check localStorage if prop value wasn't found
        const localStorageValue = localStorage.getItem(
          this.localStorageUserPrefix + key
        )
        if (localStorageValue !== null) {
          const propValue = this.stringToPropValue(key, localStorageValue)
          if (propValue !== null) value = propValue
        }

        Object.defineProperty(allProperties, key, {
          value: value,
          writable: true,
          enumerable: true
        })
      }

      Object.assign(this.cache, allProperties)
    }

    return this.cache
  }

  registerUser: registerFunction = async (userID: string) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userID}`
      }
    }

    const response = await fetch(this.apiUrl, options)
    const result = (await response.json()) as apiReturnValue

    return result
  }

  startSyncer = () => {
    this.syncer = setInterval(this.sync, 6e3)
    return this.syncer
  }

  delaySync = () => {
    if (!!this.syncer) clearInterval(this.syncer)
    setTimeout(this.startSyncer, 60e3)
  }

  private sync = async () => {
    if (!this.queue.length) return

    const updates = this.queue.reduce(
      (updates: UserProperties, item) => Object.assign(updates, item),
      {}
    )

    const result = await this.setAsync(updates)
    if (result.status === 'ok') {
      this.queue = []
    } else {
      this.delaySync()
    }
  }

  isDefault = (key: keyof UserProperties, value: any): boolean => {
    return this.defaults[key] === value
  }

  // Gets cached values, i.e. default values if not synced
  get: getFunction = (prop, ...rest) => {
    if (prop === null) return this.cache

    const properties = [prop].concat(rest)
    const result: UserProperties = {}

    for (const property of properties) {
      Object.defineProperty(result, property, {
        value: this.cache[property],
        writable: true,
        enumerable: true
      })
    }

    return result
  }

  private getDatabaseRow: () => Promise<
    undefined | { [key: string]: string }
  > = async () => {
    if (!this.userID) {
      console.error(
        'getDatabaseRow was called with undefined userID. Always init propertiesService first.'
      )
      throw new FatalError(ErrorType.USER_ID_UNDEFINED)
    }

    const options = {
      headers: {
        Authorization: `Bearer ${this.userID}`
      }
    }

    let result = undefined
    try {
      const response = await fetch(this.apiUrl, options)
      result = await response.json()
    } catch (_error) {
      // Mute error
    }

    return result
  }

  getAsync: getAsyncFunction = async (prop, ...rest) => {
    // Determine which wantedProps to get (null = all)
    const wantedProps: PropKey[] =
      prop === null
        ? (Object.keys(this.defaults) as PropKey[])
        : [prop].concat(rest)

    // Store key value pairs in result
    let result: UserProperties = {}

    // Get user row from database
    const dbRow = await this.getDatabaseRow()
    // If unsuccessful request, fall back on cache
    if (dbRow === undefined) {
      return this.get(prop, ...rest)
    }

    // Return directly with empty object if user isn't registered
    if (!Object.keys(dbRow).length) return {}

    const stored = dbRow

    for (const k in stored) {
      // Skip irrelevant database columns
      if (!wantedProps.includes(k as PropKey)) continue

      const key = k as PropKey
      const value = stored[key]
      let newValue = this.stringToPropValue(key, value)

      if (newValue === null) {
        newValue = this.cache[key]
      }

      Object.defineProperty(result, key, {
        value: newValue,
        writable: true,
        enumerable: true
      })
    }

    const receivedProps = Object.keys(result) as PropKey[]
    const missingProps = wantedProps.filter(
      prop => !receivedProps.includes(prop)
    )

    for (const key of missingProps) {
      let value = this.cache[key]

      // Check localStorage if prop value wasn't found
      const localStorageValue = localStorage.getItem(
        this.localStorageUserPrefix + key
      )
      if (localStorageValue !== null) {
        const propValue = this.stringToPropValue(key, localStorageValue)
        console.log('localstorage value', { [key]: propValue })
        if (propValue !== null) value = propValue
      }

      Object.defineProperty(result, key, {
        value: value,
        writable: true,
        enumerable: true
      })
    }

    return result
  }

  getLocal: getLocalFunction = key => {
    try {
      const stored = localStorage.getItem(this.localStoragePrefix + key)
      if (stored === null) return null
      const value = JSON.parse(stored)
      switch (key) {
        case 'uiLang':
          return value as UILanguage
        case 'onboarded':
          return value as [UILanguage, HostType][]
        case 'simulatedUser':
          return value as User
        case 'did_import_old_store':
          return value as boolean
        default:
          return value as string
      }
    } catch (_error) {
      return null
    }
  }

  set = (newValues: UserProperties, dispatchEvent = true): void => {
    for (const key in newValues) {
      const property = key as PropKey
      const newValue = newValues[property]

      // If value is empty array, delete it
      //@ts-ignore
      if (isEmptyArray(property) || newValue === null) {
        this.delete(property)
        continue
      }

      // Build URL parameters
      const paramValue = this.propValueToString(property, newValue)
      if (!paramValue.length) continue

      // Update localStorage
      // console.log(`setting localStorage.${this.localStoragePrefix + property} to "${paramValue}"`)
      localStorage.setItem(this.localStorageUserPrefix + property, paramValue)

      // Alert rest of application
      const oldValue = this.cache[property]
      if (newValue !== oldValue && dispatchEvent) {
        const detail: StorageChange = { property, newValue }
        const event = new CustomEvent('oribiStorageChange', { detail })
        this.target.dispatchEvent(event)
      }
    }

    // Update cache
    Object.assign(this.cache, newValues)

    // TODO: Move this into newValue !== oldValue statement?
    this.queue.push(newValues)
  }

  setAsync = async (newValues: UserProperties): Promise<apiReturnValue> => {
    if (!this.userID) {
      return {
        status: 'failed',
        msg:
          'propertiesService.set was called with undefined userID. Always init propertiesService first'
      }
    }

    // Update cache and localStorage
    this.set(newValues)

    // Build URL to update database
    let url = this.apiUrl + '?'

    for (const key in newValues) {
      const property = key as PropKey
      const newValue = newValues[property]

      if (isEmptyArray(newValue) || newValue === null) {
        url += `${property}&`
      } else {
        // Build URL parameters
        const paramValue = this.propValueToString(property, newValue)
        if (!paramValue.length) continue

        url += `${property}=${paramValue}&`
      }
    }

    // Remove trailing ampersand from URL
    url = url.replace(/&$/, '')

    // Update database
    const options = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.userID}`
      }
    }

    let result: apiReturnValue = { status: 'failed' }
    try {
      const response = await fetch(url, options)
      result = (await response.json()) as apiReturnValue
    } catch ({ message }) {
      if (typeof message === 'string') {
        result.msg = message
      }
      if (message === 'Request not allowed') result.maxQuotaReached = true
    }

    return Promise.resolve(result)
  }

  setLocal: setLocalFunction = properties => {
    for (const key in properties) {
      const value = properties[key as keyof LocalProperties]
      if (value === undefined) continue
      try {
        localStorage.setItem(
          this.localStoragePrefix + key,
          JSON.stringify(value)
        )
      } catch (_error) {
        // Wait for issue #97
      }
    }
  }

  delete = (property: PropKey): void => {
    this.queue.push({ [property]: null })

    delete localStorage[this.localStorageUserPrefix + property]

    // Reset cache value to default
    Object.defineProperty(this.cache, property, {
      value: this.defaults[property],
      writable: true,
      enumerable: true
    })
  }

  deleteAsync = (property: PropKey): Promise<apiReturnValue> => {
    this.delete(property)

    return this.setAsync({ [property]: null })
  }

  deleteLocal: deleteLocalFunction = key => {
    try {
      delete localStorage[this.localStoragePrefix + key]
    } catch (_error) {
      // Wait for issue #97
    }
  }

  // Resolve true if successful
  clear = async (): Promise<apiReturnValue> => {
    // Update database
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.userID}`
      }
    }

    let result: apiReturnValue = { status: 'failed' }
    try {
      const response = await fetch(this.apiUrl, options)
      if (!response.ok) throw new Error(response.statusText)

      result = (await response.json()) as apiReturnValue
    } catch ({ message }) {
      if (typeof message === 'string') {
        result.msg = message
      }
    } finally {
      if (result.status === 'ok') {
        for (let property in this.defaults) {
          this.delete(property as PropKey)
        }
      }
      return Promise.resolve(result)
    }
  }

  addOnChangedListener(callback: StorageChangeCallback) {
    this.target.addEventListener('oribiStorageChange', e => {
      const event = e as CustomEvent
      const { property, newValue } = event.detail as StorageChange

      callback({
        property,
        newValue
      })
    })
  }

  // Conversion
  propValueToString = (property: PropKey, value: PropValue): string => {
    let newValue = ''
    if (value === undefined) return ''

    switch (property) {
      // string[]
      case 'userwords':
      case 'nowarns':
        newValue = encodeURIComponent((value as string[]).join(','))
        break
      // boolean
      case 'homophones':
      case 'spaces':
      case 'grammar':
      case 'sentences':
      case 'trial_expired':
        newValue = Number(value as boolean).toString()
        break
      case 'license':
      case 'trial_start':
      case 'license_key':
      case 'english_type':
      case 'listsize':
        newValue = !value ? '' : encodeURIComponent(value as string)
        break
      case 'firstlang':
        switch (value) {
          case FirstLang.SV:
            newValue = 'sv'
            break
          case FirstLang.DA:
            newValue = 'da'
            break
          case FirstLang.NO:
            newValue = 'nn'
            break
          case FirstLang.DE:
            newValue = 'de'
            break
          case FirstLang.FI:
            newValue = 'fi'
            break
          default:
            newValue = 'en'
            break
        }
        break
      case 'school_id':
        newValue = !value ? '' : value.toString()
        break
      default:
        console.warn(`Unhandled conversion of ${property}: ${value} to string.`)
        break
    }

    return newValue
  }

  stringToPropValue = (property: PropKey, value: any): PropValue | null => {
    if (typeof value !== 'string') return null

    let newValue = null //: PropValue | null = null

    switch (property) {
      // string[]
      case 'userwords':
      case 'nowarns':
        newValue = decodeURIComponent(value).split(',')
        break
      // boolean
      case 'homophones':
      case 'spaces':
      case 'grammar':
      case 'sentences':
      case 'trial_expired':
        if (value === '1' || value === '0') newValue = !!Number(value)
        break
      case 'listsize':
        if (value === '1' || value === '2' || value === '3') newValue = value
        break
      case 'english_type':
        if (value === 'american' || value === 'british') newValue = value
        break
      case 'firstlang':
        switch (value) {
          case 'sv':
            newValue = FirstLang.SV
            break
          case 'da':
            newValue = FirstLang.DA
            break
          case 'nn':
          case 'no':
          case 'nb':
            newValue = FirstLang.NO
            break
          case 'de':
            newValue = FirstLang.DE
            break
          case 'fi':
            newValue = FirstLang.FI
            break
          default:
            newValue = FirstLang.NULL
            break
        }
        break
      case 'school_id':
        newValue = Number(value)
        break
      // Regular non-predictable strings
      case 'license':
      case 'trial_start':
      case 'license_key':
        newValue = decodeURIComponent(value)
        break
      default:
        console.warn(
          `Unhandled conversion of ${property}: ${value} to PropValue.`
        )
        break
    }

    return newValue
  }
}

type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
    ? T[P]
    : T[P] | undefined
}
