import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'
import { getBrowserInfo } from '../api/browserInfo'
import Translator from '../api/i18n'
import { OribiApp } from '../types'
import { BrowserInfoView } from './Settings'

let documentUrl = ''

// console.log('Calling office.onreeady in Error')
// Office.onReady()
//   .then(() => {
//     documentUrl = Office.context.document.url
//   })
//   .catch(() => {})

// Zero-based error code index
export enum ErrorType {
  GENERIC, // = 0 and so on...
  NO_OFFICE,
  LOGIN,
  OFFLINE,
  WORD_API,
  ONENOTE_API,
  TEAMS_BROWSER,
  APP_IS_NULL,
  MACOS,
  SAFARI,
  TTS_UNAVAILABLE,
  TTS_LANG_UNAVAILABE,
  USER_ID_UNDEFINED,
  USER_ID_UNRECOGNIZED,
  NO_LOCALSTORAGE
}

export class SoftError extends Error {
  type: ErrorType

  constructor(type: ErrorType, message?: string) {
    super(message)
    this.type = type
  }
}

export class FatalError extends Error {
  type: ErrorType

  constructor(type: ErrorType, message?: string) {
    super(message)
    this.type = type
  }
}

interface FatalErrorMessageProps {
  error: FatalError
  app?: OribiApp
}

export const FatalErrorMessage = (props: FatalErrorMessageProps) => {
  const { error } = props
  const { message, type } = error
  const i18n = new Translator()

  // FatalErrorMessage can display two parallel messages. If you provide an error
  // message to the new FatalError, it will be displayed directly after the header.
  // The FatalErrorMessage component can also declare an "internal" message based
  // on the error type but doesn't have access to the app context (OribiApp,
  // OfficeInfo, User etc.)

  let isWorthRestart: boolean = true
  let helpText: string = ''

  const action: ActionBtnProps = {
    url: '',
    label: '',
    color: 'teal'
  }

  switch (type) {
    case ErrorType.APP_IS_NULL:
      helpText = i18n._('error_appnull', [window.location.host])
      break
    case ErrorType.NO_OFFICE:
      helpText = i18n._('error_office_api')
      isWorthRestart = false
      break
    case ErrorType.ONENOTE_API:
    case ErrorType.WORD_API:
    case ErrorType.MACOS:
    case ErrorType.SAFARI:
      isWorthRestart = false
      break
    case ErrorType.NO_LOCALSTORAGE:
      helpText = i18n._('error_localStorage')
      isWorthRestart = false
      break
    case ErrorType.TEAMS_BROWSER:
      const app = props.app || i18n._('undefined_app')
      helpText = i18n._('error_teams_browser', [app])

      action.url = documentUrl
      action.label = i18n._('open_in_browser')
      action.color = 'purple'
      isWorthRestart = false
      break
    case ErrorType.OFFLINE:
      helpText = 'Offline'
      break
    default:
      break
  }

  // const errorCode = type.toString()

  return (
    <Modal open={true} basic>
      <Header as='h1' content={i18n._('fatal_error_header')} />
      <Modal.Content>
        {message && <p className='error-message'>{message}</p>}
        {helpText && <p className='error-help-text'>{helpText}</p>}
        {!message && !helpText && (
          <p className='error-code'>Error code #{type.toString()}</p>
        )}
        {!!action.label && !!action.url && <ActionBtn action={action} />}
        <BrowserInfoView info={getBrowserInfo()} />
        <p>
          {isWorthRestart && `${i18n._('error_try_restart')} `}
          {!action.url && i18n._('error_contact_developer')}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          inverted
          as='a'
          icon='help'
          content={i18n._('support_header')}
          href={i18n._('support_link')}
          target='_blank'
          rel='noopener noreferrer'
        />
        {isWorthRestart && (
          <Button
            primary
            icon='refresh'
            content={i18n._('restart')}
            onClick={() => {
              localStorage.clear()
              window.location.reload()
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

interface ActionBtnProps {
  color:
    | 'teal'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'olive'
    | 'green'
    | 'blue'
    | 'violet'
    | 'purple'
    | 'pink'
    | 'brown'
    | 'grey'
    | 'black'
    | 'facebook'
    | 'google plus'
    | 'vk'
    | 'twitter'
    | 'linkedin'
    | 'instagram'
    | 'youtube'
    | undefined
  url: string
  label: string
}

const ActionBtn = (props: { action: ActionBtnProps }) => {
  const { label, url, color } = props.action

  return (
    <>
      <p style={{ overflowWrap: 'break-word' }}>
        <Button
          fluid
          content={label}
          icon='window restore outline'
          color={color}
          onClick={() => {
            window.open(url)
          }}
        />
      </p>
      <p>
        <a
          style={{ wordBreak: 'break-all' }}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {encodeURI(url)}
        </a>
      </p>
    </>
  )
}
