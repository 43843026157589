import sv from '../_locales/sv/messages.json'
import en from '../_locales/en/messages.json'
import da from '../_locales/da/messages.json'
import nb from '../_locales/nb/messages.json'
import de from '../_locales/de/messages.json'
import { OribiApp } from '../types'
import PropertiesService from './storage'

export type UILanguage = 'en'|'sv'|'da'|'nb'|'de' // |'fi'
type UILanguageGetter = (app?: OribiApp) => UILanguage
export type MessageGetter = (messageName: string, substitutions?: string[]) => string

export const availableUiLangs: UILanguage[] = ['sv', 'en', 'da', 'nb', 'de']

export default class Translator {
  lang: UILanguage
  fallbackLang: UILanguage
  locales: {
    sv: any,
    en: any,
    da: any,
    nb: any,
    de: any
  }

  _: MessageGetter // Shorthand method for getMessage

  constructor(lang?: UILanguage, app?: OribiApp) {
    this.fallbackLang = 'en'
    this.locales = {
      sv, en, da, nb, de
    }

    if ( lang ) {
      this.lang = lang
    } else {
      this.lang = this.getUILanguage(app)
    }

    this._ = this.getMessage.bind(this)
  }

  getUILanguage: UILanguageGetter = (app) => {
    let lang: UILanguage = 'en'

    switch( app ) {
      case OribiApp.STAVA_REX:
        lang = 'sv'
        break
      case OribiApp.VERITYSPELL:
        lang = 'en'
        break
      case OribiApp.STAVLET:
        lang = 'da'
        this.fallbackLang = 'sv'
        break
      case OribiApp.SCHREIBREX:
        lang = 'de'
        break
      default: // e.g. OribiApp.SPELLRIGHT
        const navigatorLang = navigator.language.split(/_|-/)[0] as UILanguage
        let cachedLang: UILanguage

        if ( app ) {
          cachedLang = new PropertiesService(app).getLocal('uiLang') as UILanguage
        } else {
          cachedLang = navigatorLang
        }

        if ( availableUiLangs.includes(cachedLang) ) {
          lang = cachedLang
        } else if ( availableUiLangs.includes(navigatorLang) ) {
          lang = navigatorLang
        }

        if ( lang === 'nb' || lang === 'da' ) this.fallbackLang = 'sv'
        
        break
    }

    return lang
  }

  getMessage: MessageGetter = (messageName, substitutions) => {
    type Replacer = (match: string, group: string) => string
    interface Placeholders {
      [key: string]: {
        content: string
      }
    }
    interface Translation {
      message: string
      placeholders: Placeholders
    }

    let translation = this.locales[this.lang][messageName] as Translation
    if ( !translation ) translation = {
      message: '',
      placeholders: {}
    }

    // Look for translation in fallback language if not found in preferred one
    if ( !translation.message && this.lang !== this.fallbackLang ) {
      translation = this.locales[this.fallbackLang][messageName]
    }
    
    if ( !translation || !translation.message ) return messageName

    const { message } = translation
    // Return message as is if no substitutions provided or if translation doesn't
    // have any placeholders specified
    if ( !substitutions || !substitutions.length || !translation.placeholders ) {
      return message
    }

    const messageReplacement: Replacer = (match, key) => {
      const placeholder = translation.placeholders[key]
      if ( !placeholder ) return match

      const { content } = placeholder
      if ( !content ) return match

      // Matches $1, $2, $3 up to $9 in the placeholder.content text
      const nthSub = /\$([1-9])/g

      // Replace nthSub with corresponding value (-1) in substitutions array
      const contentReplacement: Replacer = (match, nr) =>
        substitutions[Number(nr) - 1] || match

      return content.replace(nthSub, contentReplacement)
    } // end messageReplacement()

    // Matches text wrapped in $dollar$ signs ((match, key) => ('$dollar$', 'dollar'))
    const substitutionTags = /\$([^$]+)\$/g 

    return message.replace(substitutionTags, messageReplacement)
  }

  setLanguage = (newLang: UILanguage) => {
    this.lang = newLang
  }
}