import { HostType } from '../../types'

export type HTMLString = string

export interface OneNoteParagraph {
  text: string // Representation of the paragraph text, with an appended newline (\n)
  id: string
  item: OneNote.Paragraph
  html: HTMLString
  outlineItemId: string
  startOffset: number
  endOffset: number
}

export interface OneNoteOutLine {
  id: string
  paragraphs: OneNoteParagraph[]
  startOffset: number
  endOffset: number
}

export interface DocumentBody {
  text: string
  paragraphs?: OneNoteParagraph[]
  outlines?: OneNoteOutLine[]
}

let activeOneNoteParagraph: OneNoteParagraph | undefined = undefined
let selectionStart: number = 0
let selectionLength: number = 0

export const getOneNoteOutlines = async (): Promise<OneNoteOutLine[]> => {
  await Office.onReady()
  const OneNote = window.OneNote

  return OneNote.run(async context => {
    const page = context.application.getActivePageOrNull()

    page.load('contents')
    await context.sync()

    const outlineItems = page.contents.items.filter(
      item => item.type === OneNote.PageContentType.outline
    )

    const outlines = []
    let startOffset = 0

    for (const outlineItem of outlineItems) {
      outlineItem.load('outline')
      await context.sync()

      const outline: OneNoteOutLine = {
        id: outlineItem.id,
        paragraphs: [],
        startOffset: -1,
        endOffset: -1
      }

      outlineItem.outline.load('paragraphs')
      await context.sync()

      const richTextParagraphs = outlineItem.outline.paragraphs.items.filter(
        outlineItem => outlineItem.type === OneNote.ParagraphType.richText
      )

      for (const paragraphItem of richTextParagraphs) {
        paragraphItem.load('id,richText/text')
        await context.sync()

        const html = paragraphItem.richText.getHtml()
        await context.sync()

        let { text } = paragraphItem.richText
        text += '\n'
        // text = String.fromCharCode(182) + text // -> ¶text

        const paragraph: OneNoteParagraph = {
          text: text,
          html: html.value,
          id: paragraphItem.id,
          item: paragraphItem,
          outlineItemId: outlineItem.id,
          startOffset: startOffset,
          endOffset: startOffset + text.length
        }

        outline.startOffset = startOffset
        outline.endOffset = startOffset + text.length

        startOffset += text.length

        outline.paragraphs.push(paragraph)
      }

      const firstParagraph = outline.paragraphs[0]
      if (!!firstParagraph) {
        outline.startOffset = firstParagraph.startOffset
      } else {
        outline.startOffset = 0
      }

      const lastParagraph = outline.paragraphs[outline.paragraphs.length - 1]
      if (!!lastParagraph) {
        outline.endOffset = lastParagraph.endOffset
      } else {
        outline.endOffset = 0
      }

      outlines.push(outline)
    }

    return outlines
  })
}

export async function getOneNoteRichTextParagraphs(): Promise<
  OneNoteParagraph[]
> {
  return new Promise(async resolve => {
    await Office.onReady()
    const OneNote = window.OneNote

    OneNote.run(async context => {
      const page = context.application.getActivePageOrNull()

      page.load('contents')
      await context.sync()

      const outlineItems = page.contents.items.filter(
        item => item.type === OneNote.PageContentType.outline
      )

      const paragraphs = []
      let startOffset = 0

      for (const outlineItem of outlineItems) {
        outlineItem.load('outline')
        await context.sync()

        outlineItem.outline.load('paragraphs')
        await context.sync()

        const richTextParagraphs = outlineItem.outline.paragraphs.items.filter(
          outlineItem => outlineItem.type === OneNote.ParagraphType.richText
        )

        for (const paragraphItem of richTextParagraphs) {
          paragraphItem.load('id,richText/text')
          await context.sync()

          const html = paragraphItem.richText.getHtml()
          await context.sync()

          let { text } = paragraphItem.richText
          text += '\n'
          // text = String.fromCharCode(182) + text // -> ¶text

          const paragraph: OneNoteParagraph = {
            text: text,
            html: html.value,
            id: paragraphItem.id,
            item: paragraphItem,
            outlineItemId: outlineItem.id,
            startOffset: startOffset,
            endOffset: startOffset + text.length
          }

          startOffset += text.length
          paragraphs.push(paragraph)
        }
      }

      return resolve(paragraphs)
    })
  })
}

export function getDocumentBody(host: HostType): Promise<DocumentBody> {
  return new Promise(async resolve => {
    switch (host) {
      case HostType.Word:
        await Office.onReady()
        const Word = window.Word

        Word.run(async context => {
          const body = context.document.body
          context.load(body, 'text')

          await context.sync()
          return resolve({
            text: body.text
          })
        })
        break
      case HostType.OneNote:
        const paragraphs = await getOneNoteRichTextParagraphs()
        const outlines = await getOneNoteOutlines()
        const text = paragraphs.map(p => p.text).join('')
        resolve({
          text,
          paragraphs,
          outlines
        })
        break
      default:
        return resolve({
          text: ''
        })
    }
  })
}

export const setSelection = async (
  host: HostType,
  text: string,
  start: number
) => {
  switch (host) {
    case HostType.Word:
      await _selectTextInWord(text, start)
      break
    case HostType.OneNote:
      await _setSelectionInOneNote(start, text.length)
      break
    default:
      throw new Error(`Can't set selection in ${host}`)
  }
}

async function _selectTextInWord(text: string, startOffset: number) {
  await Office.onReady()

  return Word.run(async context => {
    const matchCase = true
    const results = context.document.body.search(text, { matchCase })
    const body = context.document.body

    results.load('items')
    body.load('text')

    await context.sync()

    const rangeItems = results.items
    let rangeItemIndex = 0

    // If document text contains more than one occurrence of selection text,
    // find out which one to select
    if (rangeItems.length > 1) {
      // Start with part of string leading up to startOffset
      let POS = body.text.substring(0, startOffset)

      while (POS.includes(text) && rangeItemIndex <= rangeItems.length) {
        rangeItemIndex++

        // Remove part of string up to first index of selection text and repeat
        POS = POS.substring(POS.indexOf(text) + text.length)
      }
    }

    const rangeItem = rangeItems[rangeItemIndex]

    if (!rangeItem) {
      throw new Error(`SelectionError@${startOffset}: "${text}"`)
    }

    rangeItem.select(Word.SelectionMode.select)

    return context.sync()
  })
}

// In OneNote, we can't really "set selection" since the API provides us with no
// such feature. We can however replace an entire paragraph, so let's at least
// cache the paragraph in question so that we know which one to replace later on.
const _setSelectionInOneNote = async (start: number, length: number) => {
  await Office.onReady()
  const documentBody = await getDocumentBody(HostType.OneNote)
  const { paragraphs } = documentBody

  if (!paragraphs || !paragraphs.length) return

  const end = start + length
  activeOneNoteParagraph = paragraphs.find(
    ({ startOffset, endOffset }) => startOffset <= start && endOffset >= end
  )
  selectionStart = start
  selectionLength = length
}

// const getItemAt = index => arr.find(item => index >= item.a && index < item.b)
export function replaceOneNoteParagraph(
  paragraphObj: OneNoteParagraph,
  newHTML: HTMLString
): Promise<boolean> {
  if (!paragraphObj || !newHTML) return Promise.resolve(false)

  return new Promise(async resolve => {
    await Office.onReady()
    const OneNote = window.OneNote

    OneNote.run(async context => {
      const page = context.application.getActivePage()
      const { contents } = page

      const item = contents.getItem(paragraphObj.outlineItemId)
      await context.sync()

      if (!item) return resolve(false)

      item.load('outline')
      await context.sync()

      item.outline.load('paragraphs')
      await context.sync()
      const { items } = item.outline.paragraphs

      const paragraph = items.find(item => item.id === paragraphObj.id)
      if (!paragraph) return resolve(false)

      paragraph.insertRichTextAsSibling(OneNote.InsertLocation.after, newHTML)
      paragraph.delete()

      context.sync()
      resolve(true)
    })
  })
}

type OribiSelectionMode = 'start' | 'end' | 'select'
export const replaceSelection = async (
  host: HostType,
  newText: string,
  selectionMode: OribiSelectionMode = 'end'
) => {
  switch (host) {
    case HostType.Word:
      await replaceSelectionInWord(newText, selectionMode)
      break
    case HostType.OneNote:
      await replaceSelectionInOneNote(newText)
      break
    default:
      throw new Error(`Can't replace selection in ${host}`)
  }
}

const replaceSelectionInWord = async (
  newText: string,
  selectionMode: OribiSelectionMode
): Promise<void> => {
  await Office.onReady()

  return new Promise((resolve, reject) => {
    Office.context.document.setSelectedDataAsync(
      newText,
      {},
      async ({ status }) => {
        if (status === Office.AsyncResultStatus.Failed) {
          return reject(new Error())
        }

        await window.Word.run(context => {
          let wordSelectionMode: Word.SelectionMode = Word.SelectionMode.end
          if (selectionMode === 'start') {
            wordSelectionMode = Word.SelectionMode.start
          } else if (selectionMode === 'select') {
            wordSelectionMode = Word.SelectionMode.select
          }

          context.document.getSelection().select(wordSelectionMode)
          return context.sync()
        })

        resolve()
      }
    )
  })
}

const replaceSelectionInOneNote = async (newText: string) => {
  if (!activeOneNoteParagraph) return

  const { text, startOffset, outlineItemId, id } = activeOneNoteParagraph

  const start = selectionStart - startOffset
  const end = start + selectionLength

  const newParagraphText =
    text.substring(0, start) + newText + text.substring(end)

  await Office.onReady()
  const OneNote = window.OneNote

  await new Promise(resolve => {
    OneNote.run(async context => {
      const page = context.application.getActivePage()
      const { contents } = page

      const item = contents.getItem(outlineItemId)
      await context.sync()
      if (!item) return

      item.load('outline')
      await context.sync()

      item.outline.load('paragraphs')
      await context.sync()
      const { items } = item.outline.paragraphs

      const paragraph = items.find(item => item.id === id)
      if (!paragraph) return

      paragraph.insertHtmlAsSibling(
        OneNote.InsertLocation.before,
        newParagraphText
      )
      paragraph.delete()

      return context.sync().then(resolve)
    })
  })
}

export function selectBeginning(host: HostType): Promise<void> {
  if (host !== HostType.Word) return Promise.resolve()

  return window.Word.run(context => {
    context.document.body.select('Start')
    return context.sync()
  })
}

interface DocumentSelection {
  selectionStart: number
  selectionLength: number
}

/**
 * Get length of selection using the common getSelectedDataAsync method.
 *
 * @returns {number} - Length of selected text
 */
function _getSelectionLength(): Promise<number> {
  return new Promise(resolve => {
    Office.context.document.getSelectedDataAsync(
      Office.CoercionType.Text,
      ({ value: selectedText }) => {
        // Fall back on 0 (caret) if no selection was found

        resolve(typeof selectedText === 'string' ? selectedText.length : 0)
      }
    )
  })
}

/**
 * Get selection start by creating a range from start of document to
 * current selection and measuring the text length in the range
 *
 * @returns {number} - Index of caret or start of selection
 */
async function _getSelectionStartInWord(): Promise<number> {
  return await Word.run(async context => {
    const startOfDocument = context.document.body.getRange(
      Word.RangeLocation.start
    )
    const currentSelectionStart = context.document
      .getSelection()
      .getRange(Word.RangeLocation.start)

    const rangeToCaret = startOfDocument.expandTo(currentSelectionStart)

    rangeToCaret.load('text')

    try {
      await context.sync()
    } catch {
      // Can't expand range to document header or footer.
      // Check currentSelectionStart.parentBody.load('type') for more info.
      // Just return 0 for now.
      return 0
    }

    return rangeToCaret.text.length
  })
}

export async function getDocumentSelection(
  host: HostType
): Promise<DocumentSelection | undefined> {
  if (host !== HostType.Word) return undefined

  const selectionLength = await _getSelectionLength()
  const selectionStart = await _getSelectionStartInWord()

  return {
    selectionLength,
    selectionStart
  }
}
