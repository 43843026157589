import React, {
  createRef,
  RefObject,
  useEffect,
  useState,
  useContext
} from 'react'
import { Message, Form } from 'semantic-ui-react'
import { SelectedWordContext } from '../context/SelectedWord'
import './style.css'
const src = 'https://lex.oribi.se'

export default () => {
  const iframeRef: RefObject<HTMLIFrameElement> = createRef()

  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [unavailable, setUnavailable] = useState(false)

  const { word } = useContext(SelectedWordContext)

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setUnavailable(true)
    }, 5000)

    const handleIframeLoaded = () => {
      clearTimeout(timeout)
      setIframeLoaded(true)

      // console.log('Parent - iframe finished loading')
    }

    const handleIframeMessage = (event: MessageEvent) => {
      // Only handle messages from dictionary iframe
      if (event.origin !== src) return

      if (event.data === 'DOMContentLoaded') {
        handleIframeLoaded()
      }
    }

    window.addEventListener('message', handleIframeMessage)
    return () => {
      if (timeout !== null) clearTimeout(timeout)
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [iframeRef])

  const wrapperStyle = {
    marginBottom: -10,
    backgroundColor: '#edebe9',
    minHeight: 'calc(100vh - 41px)',
    overflow: 'hidden',
    background: '#f4f4f4'
  }

  const frameStyle = {
    height: 'calc(100vh - 41px)',
    width: '100%',
    border: 'none',
    display: iframeLoaded ? 'block' : 'none'
  }

  const iframeSrc = `${src}${!!word ? `/?q=${encodeURIComponent(word)}` : ''}`

  return (
    <div style={wrapperStyle}>
      {!iframeLoaded && !unavailable && (
        <div style={{ margin: 12 }}>
          <Form>
            <Form.Input
              style={{ fontSize: '16px' }}
              spellCheck='false'
              autoCorrect='off'
              autoCapitalize='none'
              autoComplete='off'
              maxLength={40}
              value={word}
              icon='search'
              placeholder='Sök'
              disabled
              loading
            />
          </Form>
        </div>
      )}

      {unavailable && !iframeLoaded && (
        <Message
          style={{ margin: 16 }}
          error
          header='Ordboken ej tillgänlig'
          content='Servern otillgänglig. Försök igen senare och kontakta Oribi om problemet kvarstår.'
        />
      )}

      <iframe
        ref={iframeRef}
        src={iframeSrc}
        title='LexinOri'
        allowFullScreen={false}
        width={window.innerWidth}
        height={window.innerHeight}
        style={frameStyle}
      ></iframe>
    </div>
  )
}
