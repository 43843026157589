import Translator, { UILanguage } from './api/i18n'
import { manifests } from './_manifest.json'
import { GA4Config } from './api/ga4'

export type AppManifest = {
  guid: string
  aadClientId: string
  defaultLocale: string
  name: string
  description: string
  hostname: string
  getStartedTitle: string
  getStartedDescription: string
  taskpaneButtonTooltip: string
  slug: string
  iconDir: string
  suffix: string
}

export enum OribiApp {
  STAVA_REX = 'Stava Rex',
  SPELLRIGHT = 'SpellRight',
  VERITYSPELL = 'VeritySpell',
  STAVLET = 'StavLet',
  SCHREIBREX = 'SchreibRex'
}

function findManifest(suffix: string) {
  return manifests.find(manifest => manifest.suffix === suffix) as AppManifest
}

const LOCALHOST = findManifest('localhost')
const ROOK = findManifest('')
const ROOK_STAGING = findManifest('staging')
const STAVA_REX = findManifest('stavarex')
const SPELLRIGHT = findManifest('spellright')
const VERITYSPELL = findManifest('verityspell')
const STAVLET = findManifest('stavlet')
const SCHREIBREX = findManifest('schreibrex')

// If undefined (Rook), display app switcher
// If null, display FatalErrorMessage
export function determineApp(host: string): OribiApp | undefined | null {
  let app = null

  // Check host value first
  switch (host) {
    case STAVA_REX.hostname:
      app = OribiApp.STAVA_REX
      break
    case SPELLRIGHT.hostname:
      app = OribiApp.SPELLRIGHT
      break
    case VERITYSPELL.hostname:
      app = OribiApp.VERITYSPELL
      break
    case STAVLET.hostname:
      app = OribiApp.STAVLET
      break
    case SCHREIBREX.hostname:
      app = OribiApp.SCHREIBREX
      break
    case ROOK.hostname:
    case ROOK_STAGING.hostname:
    case LOCALHOST.hostname:
      app = undefined
      break
    default:
      break
  }

  // If no match (undefined), AppSwitcher should be enabled
  return app
}

interface AppToRun {
  app?: OribiApp | null
  displaySwitcher: boolean
}

export function determineAppToRun(): AppToRun {
  const { host } = window.location
  const cachedApp = localStorage.oribiAppToRun
  const app = cachedApp || determineApp(host)
  const displaySwitcher = !!cachedApp

  return {
    app,
    displaySwitcher
  }
}

export function getPurchaseLink(app: OribiApp, lang: UILanguage): string {
  switch (app) {
    case OribiApp.STAVA_REX:
      return 'https://oribisoftware.com/sv/shop/?edd_action=add_to_cart&download_id=175'
    case OribiApp.SPELLRIGHT:
      return lang === 'sv'
        ? 'https://oribisoftware.com/sv/shop/?edd_action=add_to_cart&download_id=188'
        : 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=607'

    case OribiApp.VERITYSPELL:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=603'
    case OribiApp.STAVLET:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=601'
    case OribiApp.SCHREIBREX:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=605'
  }
}

export function getEddIds(app: OribiApp): number[] {
  switch (app) {
    case OribiApp.STAVA_REX:
      return [175]
    case OribiApp.SPELLRIGHT:
      return [188, 607, 460, 599]
    case OribiApp.VERITYSPELL:
      return [603, 47133]
    case OribiApp.STAVLET:
      return [601]
    case OribiApp.SCHREIBREX:
      return [605]
  }
}

export const hasDictionary = (app: OribiApp): boolean => {
  if (app === OribiApp.STAVA_REX) return true
  // if ( app === OribiApp.SPELLRIGHT ) return true
  return false
}

export const getAppSlug = (app: OribiApp) =>
  app.replace(/\s/g, '-').toLowerCase()

export type IconSize = 16 | 32 | 128 | 256
export interface IconUrlOptions {
  size?: IconSize
  transparent?: boolean
}
export const getIconUrl = (app: OribiApp, options?: IconUrlOptions): string => {
  const slug = getAppSlug(app)
  const { size = 128, transparent = false } = options || {}

  return `/icons/${slug}/${
    transparent ? 'transparent_' : ''
  }icon_${size}x${size}.png`
}

export const getManualUrl = (app: OribiApp, lang?: UILanguage): string => {
  let url: string
  switch (app) {
    case OribiApp.STAVA_REX:
      url = 'https://oribi.se/guide/stava-rex-365/'
      break
    case OribiApp.SPELLRIGHT:
      url =
        lang === 'sv'
          ? 'https://oribi.se/guide/spellright-365/'
          : 'https://oribi.se/en/guide/spellright-docs/'
      break
    case OribiApp.VERITYSPELL:
      url = 'https://oribi.se/en/guide/verityspell-docs/'
      break
    case OribiApp.STAVLET:
      url = 'https://oribi.se/en/guide/stavlet/'
      break
    case OribiApp.SCHREIBREX:
      url = 'https://oribi.se/en/guide/schreibrex/'
      break
    default:
      url = new Translator(lang, app).getMessage('support_link')
      break
  }
  return url
}

// Source refers to the spellchecker language, i.e. the language of the written
// text in need of spellchecking
export type SourceLang = 'en' | 'sv' | 'da' | 'de'
export const getSourceLang = (app: OribiApp): SourceLang => {
  switch (app) {
    case OribiApp.STAVA_REX:
      return 'sv'
    case OribiApp.SCHREIBREX:
      return 'de'
    case OribiApp.STAVLET:
      return 'da'
    default:
      return 'en'
  }
}

export const getAppManifest = (app?: OribiApp): AppManifest => {
  switch (app) {
    case OribiApp.STAVA_REX:
      return STAVA_REX as AppManifest
    case OribiApp.SPELLRIGHT:
      return SPELLRIGHT as AppManifest
    case OribiApp.VERITYSPELL:
      return VERITYSPELL as AppManifest
    case OribiApp.STAVLET:
      return STAVLET as AppManifest
    case OribiApp.SCHREIBREX:
      return SCHREIBREX as AppManifest
    default:
      return (window.location.host === 'localhost:3000'
        ? LOCALHOST
        : window.location.host === 'main--rook.netlify.app'
        ? ROOK_STAGING
        : ROOK) as AppManifest
  }
}

export function getGaTrackingID(app: OribiApp): string {
  switch (app) {
    case OribiApp.STAVA_REX:
      return 'UA-72570713-10'
    case OribiApp.SPELLRIGHT:
      return 'UA-72570713-11'
    case OribiApp.VERITYSPELL:
      return 'UA-72570713-12'
    case OribiApp.STAVLET:
      return 'G-XPT3LNSKSY'
    case OribiApp.SCHREIBREX:
      return 'G-ZY8Q3KZE9Z'
    default:
      return ''
  }
}

export function getGA4Config(app: OribiApp): GA4Config | null {
  switch (app) {
    case OribiApp.STAVA_REX:
      return {
        measurement_id: 'G-KQMQJLG736',
        api_secret: 'ohjJNoOqQRKqMUDIyPrLwg',
        app_name: app.toString()
      }
    case OribiApp.SPELLRIGHT:
      return {
        measurement_id: 'G-3YH68QVMHZ',
        api_secret: 'oyn6CnQyTemYD4UkwIbUTA',
        app_name: app.toString()
      }
    default:
      return null
  }
}
