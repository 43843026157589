import { version } from '../../package.json'

const USER_ID_LENGTH = 76
const session_start = Date.now()
let user_id: string | undefined

function generateRandomString(length: number) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

function _getUserId(): string {
  if (
    user_id &&
    typeof user_id === 'string' &&
    user_id.length === USER_ID_LENGTH
  ) {
    return user_id
  }

  const fallback = generateRandomString(76)

  // Get stored user ID from localStorage
  try {
    user_id = localStorage.getItem('user_id') || undefined

    if (!user_id || user_id.length !== USER_ID_LENGTH) {
      user_id = fallback
      localStorage.setItem('user_id', user_id)
    }
  } catch {
    user_id = fallback
  }

  return user_id
}

export type GA4Config = {
  measurement_id: string
  api_secret: string
  app_name: string
}

export async function trackEvent(
  config: GA4Config,
  userProperties: Record<string, string> = {}
) {
  const session_id = Math.floor(session_start / 1000).toString()
  const { measurement_id, api_secret, app_name } = config
  const url =
    'https://www.google-analytics.com/mp/collect?measurement_id=' +
    measurement_id +
    '&api_secret=' +
    api_secret

  const userId = _getUserId()

  const events = [
    {
      name: 'ApplicationStart',
      params: {
        category: 'Application',
        engagement_time_msec: '0',
        feature: 'Spellcheck',
        session_id: session_id,
        session_time: '0'
      }
    }
  ]

  const user_properties: Record<string, { value: string }> = {
    licence_type: {
      value: 'N/A'
    },
    device_type: {
      value: 'Desktop'
    },
    app_name: {
      value: app_name
    },
    app_version: {
      value: version
    },
    browser_version: {
      value: 'N/A'
    },
    platform: {
      value: 'N/A'
    },
    os: {
      value: 'N/A'
    },
    os_version: {
      value: 'N/A'
    },
    os_language: {
      value: 'N/A'
    },
    screen_resolution: {
      value: 'N/A'
    },
    dpi: {
      value: 'N/A'
    },
    domain: {
      value: 'N/A'
    },
    screen: {
      value: 'Word Online'
    }
  }

  const userPropertyKeys = Object.keys(userProperties || {})
  for (let i = 0; i < userPropertyKeys.length; i++) {
    const key = userPropertyKeys[i]
    if (key in user_properties) {
      const value = userProperties[key]
      user_properties[key].value = value
    }
  }

  const body = {
    client_id: userId, // '6286731061',
    user_id: userId,
    user_properties: user_properties,
    events: events
  }

  const params = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8'
    }
  }

  await fetch(url, params)
}
